.profile-plans-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px;
}

.profile-plans-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  min-width: 300px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  padding: 0px 16px;
  gap: 5px;
}

.profile-plans-item {
  flex-grow: 1;
  flex-basis: 0px;
}

.profile-plans-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
  position: sticky;
  top: 0px;
  background-color: #fff;
  width: 100%;
  padding-left: 0px;
  padding-bottom: 10px;
  gap: 5px;
}

.profile-plans-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #27262e;
  padding: 5px;
  border-radius: 6px;
}

.profile-plans-content:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.profile-plans-item-see-details {
  display: flex;
  justify-content: flex-end;
  color: #438fbf;
  cursor: pointer;
  text-decoration: underline;
}

.profile-plans-state-flag-image {
  width: 32px;
  height: auto;
}

.profile-plans-state-flag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.profile-plans-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.profile-plans-item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 10px;
  height: 32px;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "salt" on;
}

.profile-plans-item-box-ok {
  background-color: #dcfce7;
  color: #22c55e;
}

.profile-plans-item-box-no-ok {
  background-color: #ececec;
  color: #757474;
}

@media only screen and (max-width: 1024px) {
  .profile-plans-sub-container {
    gap: 4px;
  }

  .profile-plans-header {
    font-size: 0.6em;
    line-height: 0.9em;
  }

  .profile-plans-content {
    font-size: 0.75em;
    line-height: 0.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .profile-plans-sub-container {
    gap: 4px;
  }

  .profile-plans-header {
    font-size: 0.7em;
    line-height: 0.9em;
  }

  .profile-plans-content {
    font-size: 0.7em;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1386px) {
  .profile-plans-sub-container {
    gap: 4px;
  }

  .profile-plans-header {
    font-size: 0.75em;
    line-height: 1em;
  }

  .profile-plans-content {
    font-size: 0.85em;
    line-height: 1em;
  }
}
