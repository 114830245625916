.select-office-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.select-office-button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select-office-city-selection-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 15px;
  justify-content: center;
}

.select-office-city-selection-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  color: #27262e;
}
