.candidate-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 16px);
  padding: 0px 8px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.candidate-details-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  height: calc(100% - 16px);
  padding: 15px;
  min-width: 700px;
  width: calc(100% - 30px);
}

.candidate-details-item {
  flex-grow: 1;
  flex-basis: 0px;
}

.candidate-details-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
  width: 100%;
  gap: 5px;
}

.candidate-details-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #27262e;
  gap: 5px;
}

@media only screen and (max-width: 1024px) {
  .candidate-details-sub-container {
    height: calc(100% - 16px);
    padding: 8px;
  }

  .candidate-details-header {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .candidate-details-content {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidate-details-sub-container {
    height: calc(100% - 16px);
    padding: 8px;
  }

  .candidate-details-header {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .candidate-details-content {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1386px) {
  .candidate-details-sub-container {
    height: calc(100% - 30px);
    padding: 15px;
  }

  .candidate-details-header {
    font-size: 0.9em;
    line-height: 1.2em;
  }

  .candidate-details-content {
    font-size: 0.9em;
    line-height: 1.2em;
  }
}
