.login-cta-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: hsl(142, 71%, 45%);
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #f0fdf4;
  border: 0px;
  cursor: pointer;
}

.login-cta-button:hover {
  background: hsl(142, 71%, 55%);
}

.login-cta-button:active {
  background: hsl(142, 71%, 45%);
}

.login-cta-button:disabled {
  background-color: hsl(142, 5%, 45%);
}

.loading-cta-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis-cta steps(4, end) 900ms infinite;
  animation: ellipsis-cta steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis-cta {
  to {
    width: 8px;
  }
}

@-webkit-keyframes ellipsis-cta {
  to {
    width: 8px;
  }
}

@media only screen and (max-width: 1024px) {
  .login-cta-button {
    padding: 12px 18px;
    font-size: 1em;
    line-height: 1.5em;
  }

  .loading-cta-text {
    width: 115px;
    text-align: left;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .login-cta-button {
    padding: 12px 18px;
    font-size: 1em;
    line-height: 1.5em;
  }

  .loading-cta-text {
    width: 115px;
    text-align: left;
  }
}

@media only screen and (min-width: 1386px) {
  .login-cta-button {
    padding: 12px 18px;
    font-size: 1em;
    line-height: 1.5em;
  }

  .loading-cta-text {
    width: 115px;
    text-align: left;
  }
}
