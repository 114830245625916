.title-text {
  font-family: Poppins;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .title-text {
    font-size: 1em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .title-text {
    font-size: 1em;
    line-height: 1.3em;
  }
}

@media only screen and (min-width: 1386px) {
  .title-text {
    font-size: 1.2em;
    line-height: 1.5em;
  }
}
