.change-field-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 50px);
  max-width: 300px;
  gap: 8px;
  overflow: hidden;
  animation: appear 250ms ease-in forwards;
}

.change-field-modal-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.change-field-modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  color: #27262e;
}

.change-field-modal-top-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  color: #747475;
}

.change-field-modal-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  overflow: hidden;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  z-index: 1500;
}

.change-field-modal-input {
  font-family: Poppins;
  border: none;
  width: 100%;
  text-align: center;
  color: #747475;
  font-size: 1em;
  border-bottom: 1px solid #747475;
}

.change-field-modal-input-button {
  width: 100%;
}

.change-field-modal-input:focus {
  outline: none;
}

.modal-large-icon {
  width: 100px;
  height: 100px;
}

.crop-preview {
  flex-grow: 1;
  flex-basis: 0px;
  overflow: auto;
}

.crop-preview-image {
  margin: auto;
}

.crop-controls {
  margin-bottom: 10px;
}

.crop-controls > * {
  margin-bottom: 3px;
}
