.shop-search-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 10px);
  margin: 3px 0px 3px 0px;
  padding: 5px;
  background-color: #ffffff;
  height: 36px;
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.shop-search-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  border: 0px;
  color: #61646b;
  border-radius: 20px;
  width: calc(100% - 16px);
  padding: 1px;
}

.shop-search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
}

.shop-search-input:focus {
  outline: none;
}

.shop-search-input:active {
  border: 0px;
}

.shop-search-input-icon {
  color: #bbc0c9;
  margin-right: 8px;
}

@media only screen and (max-width: 1024px) {
  .shop-search-input {
    padding: 5px 8px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-search-input {
    padding: 5px 8px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-search-input {
    padding: 5px 8px;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
  }
}
