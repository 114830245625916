.cart-button {
  width: 43px;
  height: 43px;
  color: #0a6789;
  border-radius: 22px;
  border: 0px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  font-size: 1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
}
