.map-top-display-container {
  display: flex;
  font-family: Poppins;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  position: absolute;
  z-index: 1079;
  top: 16px;
  left: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 8px;
  padding: 8px 8px;
}

.map-top-display-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  gap: 8px;
}

.map-top-display-place {
  font-size: 1em;
  color: #27262e;
}

.map-top-display-value {
  width: fit-content;
  font-size: 1em;
  color: #747475;
  display: flex;
  align-items: center;
  gap: 5px;
}

.map-top-display-value-number {
  line-height: 1em;
}

.map-top-display-container:hover {
  animation: display-expand 0.25s ease-out;
  animation-fill-mode: both;
}

.map-top-display-container:hover .map-top-display-summary {
  display: none;
}

.map-top-display-summary-element {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-top-display-summary-attribute {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .map-top-display-place {
    width: calc(100% - 10px);
    font-size: 0.7em;
  }

  .map-top-display-value {
    width: fit-content;
    font-weight: 400;
    font-size: 0.7em;
  }

  .map-top-display-value-number {
    line-height: 0.7em;
  }

  .map-top-display-container {
    width: 180px;
    height: 50px;
    overflow: hidden;
    transition: height 0.015s ease-out;
  }

  .map-top-display-summary {
    height: 45px;
    font-size: 0.7em;
    width: 100%;
    color: #27262e;
  }

  .map-top-display-select {
    margin-top: 5px;
  }

  @keyframes display-expand {
    0% {
      height: 45px;
    }
    100% {
      height: 85px;
      overflow: visible;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .map-top-display-place {
    width: calc(100% - 10px);
    font-size: 0.7em;
    margin-top: 10px;
  }

  .map-top-display-value {
    font-size: 0.7em;
  }

  .map-top-display-value-number {
    line-height: 0.7em;
  }

  .map-top-display-container {
    width: 180px;
    height: 55px;
    overflow: hidden;
    transition: height 0.015s ease-out;
  }

  .map-top-display-summary {
    height: 45px;
    font-size: 0.75em;
    width: 100%;
    color: #27262e;
  }

  @keyframes display-expand {
    0% {
      height: 55px;
    }
    90% {
      height: 85px;
    }
    100% {
      height: 85px;
      overflow: visible;
    }
  }
}

@media only screen and (min-width: 1386px) {
  .map-top-display-place {
    width: calc(100% - 10px);
  }

  .map-top-display-container {
    width: 220px;
    height: 57px;
    overflow: hidden;
    transition: height 0.015s ease-out;
  }

  .map-top-display-summary {
    height: 57px;
    font-size: 0.85em;
    width: 100%;
    color: #27262e;
  }

  @keyframes display-expand {
    0% {
      height: 57px;
    }
    90% {
      height: 85px;
    }
    100% {
      height: 85px;
      overflow: visible;
    }
  }
}
