.reset-password-form {
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reset-password-signup {
  margin-top: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .reset-password-logo {
    margin-bottom: 16px;
  }

  .reset-password-title {
    margin-bottom: 8px;
  }

  .reset-password-subtitle {
    margin-bottom: 26px;
  }

  .reset-password-label {
    margin-bottom: 4px;
  }

  .reset-password-input {
    margin-bottom: 24px;
  }

  .reset-password-input-password {
    margin-bottom: 8px;
  }

  .reset-password-form {
    width: 260px;
  }

  .reset-password-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .reset-password-logo {
    margin-bottom: 16px;
  }

  .reset-password-title {
    margin-bottom: 8px;
  }

  .reset-password-subtitle {
    margin-bottom: 26px;
  }

  .reset-password-label {
    margin-bottom: 4px;
  }

  .reset-password-input {
    margin-bottom: 24px;
  }

  .reset-password-input-password {
    margin-bottom: 8px;
  }

  .reset-password-form {
    width: 300px;
  }

  .reset-password-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .reset-password-logo {
    margin-bottom: 22px;
  }

  .reset-password-title {
    margin-bottom: 26px;
  }

  .reset-password-subtitle {
    margin-bottom: 26px;
  }

  .reset-password-label {
    margin-bottom: 6px;
  }

  .reset-password-input {
    margin-bottom: 24px;
  }

  .reset-password-input-password {
    margin-bottom: 16px;
  }

  .reset-password-form {
    width: 420px;
  }

  .reset-password-signup-text {
    font-size: 1em;
  }
}
