.home-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-left-pane {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.home-right-pane {
  display: none;
  background-color: #ffffff;
  border-radius: 16px;
}

.home-separator-line {
  border: 1px solid #d9d9d9;
}

@media only screen and (max-width: 1024px) {
  .home-left-pane {
    width: calc(100% - 20px);
    margin: 0px 10px 10px 10px;
    justify-content: flex-start;
    height: fit-content;
  }

  .home-right-pane {
    display: none;
  }

  .home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .home-left-pane {
    width: calc(100% - 64px);
    margin: 0px 32px 10px 32px;
  }

  .home-right-pane {
    flex-grow: 1;
    margin: 0px 8px 10px 8px;
  }

  .home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}

@media only screen and (min-width: 1386px) {
  .home-left-pane {
    width: calc(100% - 64px);
    max-width: 1300px;
    margin: 0px 32px 10px 32px;
  }

  .home-right-pane {
    flex-grow: 1;
    margin: 0px 16px 10px 16px;
  }

  .home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}
