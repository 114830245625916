.election-summary-container {
  width: calc(100% - 44px);
  height: calc(100% - 28px);
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 32px 16px 12px;
}

.election-summary-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 0.25;
  flex-basis: 25%;
  min-height: 0px;
}

@media only screen and (max-width: 1024px) {
  .election-summary-container {
    gap: 12px;
    width: calc(100% - 24px);
    margin: 12px 12px 16px 12px;
  }

  .election-summary-line {
    gap: 12px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .election-summary-container {
    gap: 12px;
  }

  .election-summary-line {
    gap: 12px;
  }
}

@media only screen and (min-width: 1386px) {
  .election-summary-container {
    gap: 24px;
  }

  .election-summary-line {
    gap: 24px;
  }
}
