.candidate-ballout-name-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  color: #27262e;
}

@media only screen and (max-width: 1024px) {
  .candidate-ballout-name-text {
    font-size: 1.2em;
    line-height: 1.3em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidate-ballout-name-text {
    font-size: 1.2em;
    line-height: 1.3em;
  }
}

@media only screen and (min-width: 1386px) {
  .candidate-ballout-name-text {
    font-size: 1.2em;
    line-height: 1.3em;
  }
}
