.shop-cart-delete-text-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ac3131;
  text-decoration: underline;
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  cursor: pointer;
  padding-left: 0px;
}

.shop-cart-delete-text-button:hover {
  color: #6f2121;
  font-weight: 700;
}

.shop-cart-delete-text-button:active {
  color: #6f2121;
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .shop-cart-delete-text-button {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-cart-delete-text-button {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-cart-delete-text-button {
    font-size: 1em;
    line-height: 1.5em;
  }
}
