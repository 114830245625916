.not-found-container {
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  font-family: Poppins;
}

.not-found-content {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  font-size: 2em;
  color: #747475;
}

.not-found-404 {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  width: 320px;
  font-size: 10em;
  color: #747475;
  opacity: 0.05;
}
