.input-label-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
}

@media only screen and (max-width: 1024px) {
  .input-label-text {
    font-size: 0.75em;
    line-height: 1em;
    height: 16px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .input-label-text {
    font-size: 0.6em;
    line-height: 0.9em;
    height: 15px;
  }
}

@media only screen and (min-width: 1386px) {
  .input-label-text {
    font-size: 0.75em;
    line-height: 1em;
    height: 16px;
  }
}
