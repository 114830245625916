.barchart-custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  padding: 10px;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
