.notebook-button {
  background: #0a6789;
  border-radius: 8px;
  height: 37px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: white;
  position: relative;
  cursor: pointer;
}

.notebook-button-small {
  height: 22px;
  width: 25px;
  min-width: 25px;
  padding: 0px;
  margin: 0px;
  border-radius: 4px;
}

.unselected-notebook-button,
.unselected-notebook-button:hover {
  background: white !important;
  color: #0a6789 !important;
  border: 1px solid #0a6789 !important;
}

.notebook-button-disabled,
.notebook-button-disabled:hover {
  background: #e9e9e9 !important;
  color: #cccccc !important;
}

.notebook-button:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.notebook-button:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

.notebook-button-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  width: calc(100% - 10px);
  padding: 10px 5px 10px 5px;
}

.notebook-button-content-small {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2px;
  width: calc(100% - 4px);
  padding: 0px 2px 0px 2px;
}
