.shop-candidature-search-result {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 239px;
  padding: 8px 0px 8px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
