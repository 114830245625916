.page-container {
  min-height: 542px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
}

.login-content {
  min-height: 542px;
  height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #053b4c;
}

.image-pane {
  background-image: url(../../resources/images/login-image.png);
}

@media only screen and (max-width: 1024px) {
  .image-pane {
    display: None;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .image-pane {
    width: 500px;
  }
}

@media only screen and (min-width: 1386px) {
  .image-pane {
    width: 800px;
  }
}
