.settings-page-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1000px;
  min-width: 0px;
}

.settings-top-container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-width: 0px;
}

.settings-top-inner-container {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.settings-bottom-container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-width: 0px;
}

.settings-bottom-inner-container {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 1024px) {
  .settings-page-container {
    gap: 8px;
  }

  .settings-top-container {
    width: calc(100% - 16px);
    margin: 0px 8px;
    gap: 8px;
  }

  .settings-bottom-container {
    width: calc(100% - 16px);
    margin: 0px 8px 8px 8px;
    padding-bottom: 32px;
    gap: 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .settings-page-container {
    gap: 16px;
  }

  .settings-top-container {
    flex-basis: 120px;
    width: calc(100% - 32px);
    margin: 0px 16px;
    gap: 16px;
  }

  .settings-bottom-container {
    flex-basis: 180px;
    width: calc(100% - 32px);
    margin: 0px 16px 16px 16px;
    gap: 16px;
  }
}

@media only screen and (min-width: 1386px) {
  .settings-page-container {
    gap: 24px;
  }

  .settings-top-container {
    flex-basis: 120px;
    width: calc(100% - 64px);
    margin: 0px 32px;
    gap: 8px;
  }

  .settings-top-inner-container {
    gap: 24px;
  }

  .settings-bottom-container {
    flex-basis: 120px;
    width: calc(100% - 64px);
    margin: 0px 32px;
    gap: 8px;
  }

  .settings-bottom-inner-container {
    gap: 24px;
  }
}
