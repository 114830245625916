.candidates-profile-page-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
  min-width: 0px;
}

.candidates-profile-top-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
}

.candidates-profile-middle-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
  flex-basis: 90px;
}

.candidates-profile-bottom-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
  flex-grow: 1;
  flex-basis: 0px;
}

.candidates-profile-info-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.candidates-profile-voting-history-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.candidates-profile-details-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.candidates-profile-candidatures-container {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

@media only screen and (max-width: 1024px) {
  .candidates-profile-page-container {
    gap: 8px;
  }

  .candidates-profile-top-container {
    flex-basis: 160px;
    width: calc(100% - 16px);
    margin: 0px 8px;
    gap: 8px;
  }

  .candidates-profile-middle-container {
    flex-basis: 70px;
    width: calc(100% - 16px);
    margin: 0px 8px;
  }

  .candidates-profile-bottom-container {
    width: calc(100% - 16px);
    margin: 0px 8px 8px 8px;
  }

  .candidates-profile-voting-history-container {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidates-profile-page-container {
    gap: 16px;
  }

  .candidates-profile-top-container {
    flex-basis: 188px;
    width: calc(100% - 32px);
    margin: 0px 16px;
    gap: 16px;
  }

  .candidates-profile-middle-container {
    flex-basis: 70px;
    width: calc(100% - 32px);
    margin: 0px 16px;
  }

  .candidates-profile-bottom-container {
    width: calc(100% - 32px);
    margin: 0px 16px 16px 16px;
  }

  .candidates-profile-voting-history-container {
    height: calc(100% - 16px);
    padding: 8px;
  }
}

@media only screen and (min-width: 1386px) {
  .candidates-profile-page-container {
    gap: 24px;
  }

  .candidates-profile-top-container {
    flex-basis: 228px;
    width: calc(100% - 64px);
    margin: 0px 32px;
    gap: 24px;
  }

  .candidates-profile-middle-container {
    flex-basis: 90px;
    width: calc(100% - 64px);
    margin: 0px 32px;
  }

  .candidates-profile-bottom-container {
    width: calc(100% - 64px);
    margin: 0px 32px 32px 32px;
  }

  .candidates-profile-voting-history-container {
    height: calc(100% - 32px);
    padding: 16px;
  }
}
