.go-back-button {
  background: linear-gradient(228.89deg, #0a6789 1.12%, #2da5c0 100%);
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 2em;
  color: white;
  position: relative;
  cursor: pointer;
  width: 220px;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.go-back-button:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.go-back-button:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

.go-back-button-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 10px);
  padding: 10px 5px 10px 5px;
}

.go-back-button-icon {
  width: 24px;
  height: 24px;
}
