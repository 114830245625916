.target-home-page-container {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

.target-home-left-pane {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.target-home-right-pane {
  display: none;
  background-color: #ffffff;
  border-radius: 16px;
}

.target-home-separator-line {
  border: 1px solid #d9d9d9;
}

.target-home-banner {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 0px;
  min-height: 0px;
  overflow: hidden;
  margin: 20px 0px 15px 0px;
  background-color: #ffffff;
  padding: 25px;
  border-radius: 15px;
}

.target-home-banner-sub-container {
  overflow: hidden;
  height: 100%;
  transition: opacity 750ms ease-out;
}

.target-home-banner-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  gap: 15px;
}

.target-home-banner-right {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .target-home-banner {
    height: 300px;
    overflow: hidden;
  }

  .target-home-banner-left {
    flex-grow: 1;
  }

  .target-home-banner-right {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .target-home-banner {
    min-height: 300px;
    overflow: hidden;
  }

  .target-home-banner-image {
    height: 100%;
  }

  .target-home-banner-left {
    width: 180px;
  }

  .target-home-banner-sub-container {
    min-height: 300px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-home-banner {
    height: 211px;
    overflow: hidden;
  }

  .target-home-banner-sub-container {
    min-height: 211px;
  }

  .target-home-banner-image {
    width: 380px;
  }

  .target-home-banner-left {
    width: 220px;
  }
}

@media only screen and (min-width: 1386px) {
  .target-home-banner {
    height: 260px;
    overflow: hidden;
  }

  .target-home-banner-sub-container {
    min-height: 260px;
  }

  .target-home-banner-image {
    width: 430px;
  }

  .target-home-banner-left {
    width: 280px;
  }
}

/* Home Banner */
@media only screen and (max-width: 1024px) {
  .target-home-left-pane {
    width: calc(100% - 20px);
    margin: 0px 10px 10px 10px;
    justify-content: flex-start;
    height: fit-content;
  }

  .target-home-right-pane {
    display: none;
  }

  .target-home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-home-left-pane {
    width: calc(100% - 64px);
    margin: 0px 32px 10px 32px;
  }

  .target-home-right-pane {
    flex-grow: 1;
    margin: 0px 8px 10px 8px;
  }

  .target-home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}

@media only screen and (min-width: 1386px) {
  .target-home-left-pane {
    width: calc(100% - 64px);
    max-width: 1300px;
    margin: 0px 32px 10px 32px;
  }

  .target-home-right-pane {
    flex-grow: 1;
    margin: 0px 16px 10px 16px;
  }

  .target-home-separator-line {
    margin: 8px 0px 8px 0px;
  }
}
