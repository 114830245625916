.page-container {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
}

.main {
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  flex-direction: column;
  animation: appear 1s ease;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-basis: 0px;
  min-height: 0;
}
