.shop-select-outer-container {
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shop-select-container {
  font-family: Poppins;
  font-style: normal;
  height: 38px;
  max-width: 100%;
  min-width: 0px;
  flex-basis: 0px;
  color: #27262e;
  text-align: center;
}

.shop-select {
  border: "1px solid black";
  box-shadow: none;
}

.shop-select-menu {
  z-index: 1080 !important;
}

.shop-select-placeholder {
  color: #bbc0c9;
  opacity: 1;
  overflow: hidden;
}
