.notebook-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.notebook-menu-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.notebook-user-name-display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f1f1f1;
  border-radius: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
  text-overflow: ellipsis;
  cursor: pointer;
  gap: 8px;
  padding: 0px 14px 0px 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.notebook-user-name-text {
  flex-grow: 1;
  text-align: center;
  color: #797881;
}

@media only screen and (max-width: 1024px) {
  .notebook-menu-container {
    flex-basis: 30px;
    padding: 5px;
    width: calc(100% - 10px);
  }

  .notebook-user-name-text {
    display: none;
  }

  .notebook-user-name-display {
    width: 32px;
    height: 32px;
    justify-content: center;
  }

  .notebook-user-photo-display {
    height: 23px;
    width: 23px;
    border-radius: 16px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .notebook-menu-container {
    flex-basis: 35px;
    padding: 10px;
    width: calc(100% - 20px);
  }

  .notebook-user-name-display {
    height: 36px;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    padding: 0px 14px 0px 8px;
  }

  .notebook-user-photo-display {
    height: 23px;
    width: 23px;
    border-radius: 16px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1386px) {
  .notebook-menu-container {
    flex-basis: 40px;
    max-height: 40px;
    padding: 15px;
    width: calc(100% - 30px);
  }

  .notebook-user-name-display {
    height: 44px;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
    padding: 0px 14px 0px 8px;
  }

  .notebook-user-photo-display {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    object-fit: cover;
  }
}
