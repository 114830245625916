.candidate-barchart-custom-tooltip {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #f1f1f1;
  border-radius: 12px;
  padding: 10px;
  font-size: 0.75em;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.candidate-barchart-custom-tooltip-section {
  padding-top: 15px;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
