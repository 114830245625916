.map-legend-container {
  display: flex;
  font-family: Poppins;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  position: absolute;
  z-index: 1050;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  border-radius: 8px;
  height: 40px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  animation: appear 250ms ease-out;
}

.map-legend-item {
  height: 100%;
  width: 40px;
  font-size: 0.7em;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .map-legend-container {
    width: fit-content;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .map-legend-container {
    width: fit-content;
  }
}

@media only screen and (min-width: 1386px) {
  .map-legend-container {
    width: fit-content;
  }
}
