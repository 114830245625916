.login-input-text {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  width: 100%;
  border: 0px;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  color: #61646b;
}

.login-input-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
}

.login-input-text:focus {
  outline: none;
}

.login-input-text:active {
  border: 0px;
}

@media only screen and (max-width: 1024px) {
  .login-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .login-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1386px) {
  .login-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}
