.shop-select-text-outer-container {
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
}

.shop-select-text-container {
  font-family: Poppins;
  font-style: normal;
  height: 46px;
  max-width: 100%;
  min-width: 0px;
  flex-basis: 0px;
  color: #27262e;
}

.shop-select-text {
  border-radius: 8px;
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.shop-select-text-menu {
  z-index: 1080 !important;
}

.shop-select-text-placeholder {
  color: #bbc0c9;
  opacity: 1;
  overflow: hidden;
}
