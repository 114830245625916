.shop-button {
  background: #0a6789;
  border-radius: 18px;
  height: 34px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: white;
  position: relative;
  cursor: pointer;
}

.unselected-shop-button {
  background: white;
  border-radius: 18px;
  height: 34px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #0a6789;
  border: 1px solid #0a6789;
  position: relative;
  cursor: pointer;
}

.shop-button:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.shop-button:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

@media only screen and (max-width: 1024px) {
  .shop-button {
    width: 60px;
    font-size: 0.5em;
    border-radius: 16px;
  }

  .unselected-shop-button {
    width: 60px;
    font-size: 0.5em;
    border-radius: 16px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-button {
    width: 105px;
  }

  .unselected-shop-button {
    width: 105px;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-button {
    width: 120px;
  }

  .unselected-shop-button {
    width: 120px;
  }
}
