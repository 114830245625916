.menu-item-group-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875em;
  line-height: 1em;
  padding-bottom: 5px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #27262e;
}

@media only screen and (max-width: 1024px) {
  .menu-item-group-text {
    font-size: 0.6875em;
    line-height: 1em;
    padding-bottom: 5px;
    letter-spacing: 0.14em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .menu-item-group-text {
    font-size: 0.6875em;
    line-height: 1em;
    padding-bottom: 5px;
    letter-spacing: 0.14em;
  }
}

@media only screen and (min-width: 1386px) {
  .menu-item-group-text {
    font-size: 0.75em;
    line-height: 1.2em;
    padding-bottom: 9px;
    letter-spacing: 0.14em;
  }
}
