.signup-form {
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup-terms-acceptance {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  gap: 8px;
}

.signup-social-login {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.signup-signup {
  margin-top: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .signup-logo {
    margin-bottom: 16px;
  }

  .signup-title {
    margin-bottom: 8px;
  }

  .signup-subtitle {
    margin-bottom: 16px;
  }

  .signup-label {
    margin-bottom: 4px;
  }

  .signup-input {
    margin-bottom: 8px;
  }

  .signup-input-password {
    margin-bottom: 8px;
  }

  .signup-form {
    width: 260px;
  }

  .signup-terms-acceptance {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .signup-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .signup-logo {
    margin-bottom: 16px;
  }

  .signup-title {
    margin-bottom: 8px;
  }

  .signup-subtitle {
    margin-bottom: 16px;
  }

  .signup-label {
    margin-bottom: 4px;
  }

  .signup-input {
    margin-bottom: 8px;
  }

  .signup-input-password {
    margin-bottom: 8px;
  }

  .signup-form {
    width: 300px;
  }

  .signup-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .signup-logo {
    margin-bottom: 22px;
  }

  .signup-title {
    margin-bottom: 16px;
  }

  .signup-subtitle {
    margin-bottom: 26px;
  }

  .signup-label {
    margin-bottom: 6px;
  }

  .signup-input {
    margin-bottom: 24px;
  }

  .signup-input-password {
    margin-bottom: 16px;
  }

  .signup-form {
    width: 420px;
  }

  .signup-signup-text {
    font-size: 1em;
  }
}
