.priorities-selection-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: 0px auto;
}

.priorities-selection-limiter {
  width: 100%;
  max-width: 400px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.priorities-selection-top {
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 120px);
  gap: 15px;
}

.target-selection-count-container {
  font-family: Poppins;
  color: #747475;
  font-size: 1em;
  text-align: center;
  margin: 5px;
}
