.sidebar {
  width: 252px;
  background-color: #ffffff;
  height: 100vh;
  min-height: 542px;
  box-shadow: 12px 0px 18px rgba(0, 0, 0, 0.06);
  transition: all 0.15s;
  z-index: 1070;
}

.contracted-sidebar {
  transition: all 0.15s;
  background-color: #ffffff;
  z-index: 1070;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    width: 252px;
    z-index: 1070;
    position: fixed;
  }

  .contracted-sidebar {
    width: 0px;
    position: absolute;
    transition: all 0.15s;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .sidebar {
    width: 252px;
  }

  .contracted-sidebar {
    width: 70px;
  }
}

@media only screen and (min-width: 1386px) {
  .sidebar {
    width: 292px;
  }

  .contracted-sidebar {
    width: 100px;
  }
}
