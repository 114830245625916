.shop-radio-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.radio-button {
  display: flex;
  align-items: center;
  gap: 4px;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #0a6789;
  border-radius: 50%;
  outline: none;
  transition: 0.2s all ease-in-out;
  position: relative;
}

input[type="radio"]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95); /* Scale down the inner circle */
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: 0.2s all ease-in-out;
}

input[type="radio"]:checked {
  background-color: #ffffff;
  border-color: #0a6789;
}

input[type="radio"]:checked::before {
  background-color: #0a6789; /* Change the color of the inner circle when checked */
}

.shop-radio-button-label {
  font-family: Poppins;
  font-weight: 400;
  line-height: 0.8em;
  color: #0a6789;
  margin-top: 4px;
}
