.shop-input-label-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;
}

.shop-input-text {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  width: 100%;
  border: 0px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #27262e;
}

.shop-input-text-invalid {
  border: 1px solid #fc6969;
}

.shop-input-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  color: #747475;
}

.shop-input-label-invalid {
  color: #fc6969;
}

.shop-input-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
}

.shop-input-text:active,
.shop-input-text:focus {
  outline: none;
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 1024px) {
  .shop-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-input-text {
    padding: 10px 14px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}
