.party-search-acronym-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  color: #27262e;
}

@media only screen and (max-width: 1024px) {
  .party-search-acronym-text {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .party-search-acronym-text {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .party-search-acronym-text {
    font-size: 0.8em;
    line-height: 1em;
  }
}
