.party-profile-filter {
  display: flex;
  max-width: calc(100vw - 16px);
}

@media only screen and (max-width: 1024px) {
  .party-profile-filter {
    width: calc(100% - 12px);
    padding: 6px 6px 8px 6px;
    height: 115px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .party-profile-filter {
    width: calc(100% - 38px);
    padding: 6px 6px 4px 32px;
    height: 115px;
  }
}

@media only screen and (min-width: 1386px) {
  .party-profile-filter {
    width: calc(100% - 44px);
    padding: 12px 12px 15px 32px;
    height: 110px;
  }
}
