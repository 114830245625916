.shop-cart-discount-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 47px;
  gap: 8px;
}

.shop-cart-total-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 57px;
}

.shop-cart-total-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.shop-total-title {
  font-family: Poppins;
  font-size: 1.4em;
  font-weight: 500;
}

.shop-subtotal-title {
  font-family: Poppins;
  font-size: 1.2em;
  font-weight: 400;
}

.shop-discount-title {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
}

.shop-cart-discount-button-container {
  width: 55px;
}

.shop-cart-discount-input-container {
  width: 195px;
}

.shop-cart-separator {
  width: 100%;
  border: 0.5px solid #dcdcdc;
}

.shop-order-details-payment-method {
  font-family: Poppins;
  font-size: 0.8em;
  font-weight: 400;
  color: #27262e;
  width: 100%;
  text-align: right;
}

.shop-order-title-text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  color: #0a6789;
  margin-bottom: 15px;
  cursor: pointer;
}

.shop-order-details-date {
  font-family: Poppins;
  font-size: 0.8em;
  font-weight: 400;
  color: #27262e;
  width: 100%;
  text-align: center;
}
