.candidate-name-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
}

.candidate-name-text a {
  text-decoration: none;
  font-weight: 400;
  color: #747475;
}

@media only screen and (max-width: 1024px) {
  .candidate-name-text {
    font-size: 0.625em;
    line-height: 0.875em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidate-name-text {
    font-size: 0.75em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .candidate-name-text {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}
