.shop-page-container {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  background-image: url(../../resources/images/pattern-light.png);
}

.shop-main {
  width: 100%;
  max-width: 475px;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: appear 1s ease;
}
