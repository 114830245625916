.elections-page-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
}

.elections-left-pane {
  display: flex;
  height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
}

.elections-right-pane {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
}

.elections-top-left-pane {
  height: 66px;
  display: flex;
  flex-direction: row;
}

.filter-select-turn {
  width: 0px;
}

.filter-select-year {
  width: 0px;
}

.filter-select-office {
  width: 0px;
}

.filter-select-local {
  width: 0px;
}

.filter-select-state {
  width: 0px;
}

.elections-bottom-left-pane {
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 1024px) {
  .elections-left-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .elections-left-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(-100vw);
    transition: transform 0.15s;
  }

  .elections-right-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .elections-right-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(100vw);
    transition: transform 0.15s;
  }

  .elections-top-left-pane {
    height: 100px;
    min-width: 0px;
    margin: 4px 6px 16px 6px;
    gap: 4px;
    flex-wrap: wrap;
  }

  .filter-select-turn {
    width: auto;
    flex-basis: 100px !important;
  }

  .filter-select-year {
    flex-grow: 1;
    flex-basis: 100px !important;
    width: auto;
  }

  .filter-select-office {
    flex-grow: 1;
    flex-basis: 100px !important;
    width: auto;
  }

  .filter-select-local {
    flex-grow: 1;
    flex-basis: 100px !important;
    width: auto;
  }

  .filter-select-state {
    flex-grow: 1;
    flex-basis: 100px !important;
    width: auto;
  }

  .elections-bottom-left-pane {
    margin: 8px 6px 4px 6px;
    padding: 14px 5px 16px 16px;
  }

  .ranking-candidates-text {
    padding: 8px 0px 0px 6px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .elections-left-pane {
    flex-basis: 0px;
    flex-grow: 5;
  }

  .elections-right-pane {
    flex-basis: 0px;
    flex-grow: 3;
  }

  .elections-top-left-pane {
    height: 120px;
    margin: 12px 6px 16px 32px;
    gap: 12px;
    flex-wrap: wrap;
  }

  .elections-bottom-left-pane {
    margin: 12px 6px 16px 32px;
    padding: 14px 5px 16px 16px;
  }

  .ranking-candidates-text {
    padding: 0px 0px 0px 32px;
  }

  .filter-select-turn {
    width: auto;
    flex-basis: 120px !important;
  }

  .filter-select-year {
    flex-grow: 1;
    flex-basis: 120px !important;
    width: auto;
  }

  .filter-select-office {
    flex-grow: 1;
    flex-basis: 180px !important;
    width: auto;
  }

  .filter-select-local {
    flex-grow: 1;
    flex-basis: 180px !important;
    width: auto;
  }

  .filter-select-state {
    flex-grow: 1;
    flex-basis: 120px !important;
    width: auto;
  }
}

@media only screen and (min-width: 1386px) {
  .elections-left-pane {
    flex-basis: 0px;
    flex-grow: 2;
  }

  .elections-right-pane {
    flex-basis: 0px;
    flex-grow: 1;
  }

  .elections-top-left-pane {
    margin: 12px 12px 16px 32px;
    gap: 16px;
  }

  .elections-bottom-left-pane {
    margin: 12px 12px 16px 32px;
    padding: 28px 10px 32px 32px;
  }

  .ranking-candidates-text {
    padding: 0px 0px 0px 32px;
  }
}
