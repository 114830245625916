.terms-container {
  font-family: Poppins;
  background-color: #f7f7f7;
  padding: 16px;
}

.terms-global-title {
  font-size: 1.6em;
  font-weight: 700;
  color: #0a6789;
}

.terms-section-title {
  font-size: 1.2em;
  font-weight: 700;
}
