.home-calendar {
  padding: 18px;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
}

.home-calendar-days {
  display: none;
}

.home-calendar-events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.no-events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-events-text {
  font-family: Poppins;
  font-weight: 400;
  color: #f1f1f1;
}

@media only screen and (max-width: 480px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .calendar-icon {
    width: 100px;
    height: auto;
  }

  .no-events-text {
    font-size: 1em;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1386px) {
  .calendar-icon {
    width: 120px;
    height: auto;
  }

  .no-events-text {
    font-size: 1.2em;
    line-height: 1.25em;
  }
}
