.target-page-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  min-width: 0px;
}

.target-main {
  width: 100%;
  max-width: 475px;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: appear 1s ease;
}
