.settings-range-outer-container {
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
}

.settings-slidecontainer {
  width: 100%;
}

.settings-slider-input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.settings-slider-input:hover {
  opacity: 1;
}

.settings-slider-input::-webkit-slider-thumb {
  cursor: pointer;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.settings-slider-input::-moz-range-thumb {
  appearance: none;
  cursor: pointer;
  position: absolute;
  width: 12px;
  height: 12px;
  right: 0px;
  top: calc(50% - 12px / 2);
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
