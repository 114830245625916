.title-bar {
  width: calc(100% - 64px);
  padding: 0px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.user-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.user-name-display {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  min-width: 120px;
  padding: 0px 14px 0px 8px;
  gap: 8px;
  background: #f1f1f1;
  border-radius: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
  text-overflow: ellipsis;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.user-name-text {
  flex-grow: 1;
  text-align: center;
  color: #797881;
}

.hamburguer-area {
  display: none;
}

.title-bar-btn {
  font-size: 1.3em;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .title-bar {
    min-height: 60px;
    width: calc(100% - 16px);
    padding: 0px 8px 0px 8px;
    gap: 10px;
  }

  .title {
    gap: 10px;
  }

  .user-details {
    display: none;
  }

  .hamburguer-area {
    display: flex;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .title-bar {
    width: calc(100% - 48px);
    min-height: 62px;
    padding: 0px 16px 0px 32px;
    gap: 10px;
  }

  .title {
    gap: 10px;
  }

  .user-details {
    gap: 10px;
  }

  .user-name-display {
    height: 36px;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
  }

  .user-photo-display {
    height: 27px;
    width: 27px;
    border-radius: 16px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1386px) {
  .title-bar {
    width: calc(100% - 64px);
    min-height: 68px;
    padding: 0px 32px 0px 32px;
    gap: 10px;
  }

  .title {
    gap: 10px;
  }

  .user-details {
    gap: 10px;
  }

  .user-name-display {
    height: 44px;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5em;
  }

  .user-photo-display {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    object-fit: cover;
  }
}
