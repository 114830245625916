.profile-profile-container {
  border: 0px;
  font-weight: 1200px;
  font-size: 1.3em;
  border-radius: 12px;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profile-profile-sub-container {
  display: flex;
  height: auto;
  min-height: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.profile-profile-image {
  width: 88px;
  height: 88px;
  border-radius: 44px;
  object-fit: cover;
}

.profile-profile-details-pane {
  flex-basis: 0px;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-profile-buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

@media only screen and (max-width: 1024px) {
  .profile-profile-container {
    width: 100%;
    max-width: 500px;
    height: calc(100% - 16px);
    padding: 8px;
  }

  .profile-profile-sub-container {
    gap: 24px;
    margin: 0px 12px;
  }

  .profile-profile-details-pane {
    gap: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .profile-profile-container {
    height: calc(100% - 16px);
    padding: 8px;
  }

  .profile-profile-sub-container {
    gap: 32px;
    margin: 0px 12px;
  }

  .profile-profile-details-pane {
    gap: 6px;
  }
}
