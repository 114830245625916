.number-widget-text {
  font-family: Poppins;
  font-weight: 500;
  color: #27262e;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .number-widget-text {
    font-size: 1em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .number-widget-text {
    font-size: 1em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .number-widget-text {
    font-size: 1.3em;
    line-height: 1.5em;
  }
}
