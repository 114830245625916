.modal-title-text {
  font-family: Poppins;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .modal-title-text {
    font-size: 1.2em;
    line-height: 1.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .modal-title-text {
    font-size: 1.2em;
    line-height: 1.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .modal-title-text {
    font-size: 1.5em;
    line-height: 2em;
  }
}
