.party-card-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border: 0px;
  font-weight: 1200px;
  font-size: 1.3em;
  border-radius: 12px;
  width: 300px;
  max-width: 300px;
  height: 136px;
  display: flex;
  flex-direction: row;
  animation: appear 1s ease;
}

.party-card-image-pane {
  width: 112px;
  height: 112px;
  margin: 12px;
}

.party-card-logo {
  width: 112px;
  height: 112px;
  object-fit: contain;
  border-radius: 10px;
}

.party-card-details-pane {
  flex-basis: 0px;
  flex-grow: 1;
  height: 112px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
