.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
}

.form-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
}

.captcha-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
