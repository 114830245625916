.party-profile-page-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
}

.party-profile-left-pane {
  display: flex;
  height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
  min-width: 0px;
}

.party-profile-right-pane {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
  min-width: 0px;
}

.party-info-container {
  display: flex;
  flex-direction: row;
  height: 220px;
}

.party-votes-chart-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  min-width: 0px;
}

.party-candidates-chart-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  min-width: 0px;
}

.party-candidates-sex-chart-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  min-width: 0px;
}

.party-profile-widget-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .party-profile-page-container {
    overflow: hidden;
  }

  .party-profile-left-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .party-profile-left-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(-100vw);
    transition: transform 0.15s;
  }

  .party-profile-right-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .party-profile-right-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(100vw);
    transition: transform 0.15s;
  }

  .party-info-container {
    width: calc(100% - 8px);
    margin: 6px 4px 6px 4px;
    height: 150px;
  }

  .party-votes-chart-container {
    width: calc(100% - 24px);
    margin: 6px 4px 12px 4px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }

  .party-candidates-chart-container {
    width: calc(100% - 24px);
    margin: 0px 4px 6px 4px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }

  .party-candidates-sex-chart-container {
    width: calc(100% - 24px);
    margin: 6px 4px 12px 4px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .party-profile-left-pane {
    flex-basis: 0px;
    flex-grow: 5;
  }

  .party-profile-right-pane {
    flex-basis: 0px;
    flex-grow: 4;
  }

  .party-info-container {
    width: calc(100% - 38px);
    margin: 3px 6px 6px 32px;
    height: 150px;
    gap: 8px;
  }

  .party-votes-chart-container {
    width: calc(100% - 54px);
    margin: 6px 6px 12px 32px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }

  .party-candidates-chart-container {
    width: calc(100% - 38px);
    margin: 0px 12px 6px 12px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }

  .party-candidates-sex-chart-container {
    width: calc(100% - 38px);
    margin: 6px 12px 12px 12px;
    padding: 8px;
    min-height: 0px;
    flex-grow: 1;
  }
}
