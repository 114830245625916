.number-widget-container {
  flex-grow: 1;
  flex-basis: 0px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.number-widget-value {
  flex-grow: 1;
  flex-basis: 0px;
}

.number-widget-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .number-widget-container {
    max-width: calc(50% - 32px);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .number-widget-container {
    max-width: calc(50% - 32px);
  }
}

@media only screen and (min-width: 1386px) {
  .number-widget-container {
    max-width: calc(50% - 36px);
  }
}
