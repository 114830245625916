.shop-candidate-candidatures-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
  padding: 24px 8px 24px 24px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.shop-candidate-candidatures-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 0px;
  flex-wrap: nowrap;
  min-width: 700px;
  width: calc(100% - 32px);
  gap: 15px;
}

.shop-candidate-candidatures-item {
  flex-grow: 1;
  flex-basis: 0px;
}

.shop-candidate-candidatures-header {
  display: flex;
  width: 100%;
  position: sticky;
  background-color: #fff;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
  top: 0px;
  gap: 5px;
  padding-bottom: 12px;
}

.shop-candidate-candidatures-content {
  display: flex;
  width: calc(100% - 10px);
  height: 55px;
  padding: 0px 5px 0px 5px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #27262e;
  gap: 5px;
  cursor: pointer;
}

.shop-candidate-candidatures-turn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #ececec;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-feature-settings: "salt" on;
  color: #757474;
}

.shop-candidate-candidatures-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 10px;
  width: 150px;
  border-radius: 16px;
  height: 32px;
  background: #ececec;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-feature-settings: "salt" on;
  color: #757474;
}

.shop-candidate-candidatures-status-elected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0px 10px;
  width: 150px;
  border-radius: 16px;
  height: 32px;
  background: #dcfce7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-feature-settings: "salt" on;
  color: #22c55e;
}

.shop-candidate-candidatures-party {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.shop-candidate-candidatures-party-image {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .shop-candidate-candidatures-header {
    font-size: 0.7em;
    line-height: 1em;
  }

  .shop-candidate-candidatures-content {
    font-size: 0.8em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-candidate-candidatures-header {
    font-size: 0.7em;
    line-height: 1em;
  }

  .shop-candidate-candidatures-content {
    font-size: 0.6em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-candidate-candidatures-header {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .shop-candidate-candidatures-content {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}
