#dataelege-animated-maker {
  animation: eRWCJGf341a2_to__to 750ms linear infinite normal forwards;
}

.leaflet-marker-icon {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes eRWCJGf341a2_to__to {
  0% {
    transform: translate(0px, -20px) scale(125%, 100%);
    animation-timing-function: cubic-bezier(0.33333, 0.66667, 0.66667, 1);
  }
  40% {
    transform: translate(0px, -35px);
    animation-timing-function: cubic-bezier(0.33333, 0, 0.66667, 0.33333);
  }
  80% {
    transform: translate(0px, -20px) scale(110%, 100%);
  }
  100% {
    transform: translate(0px, -20px) scale(125%, 100%);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
