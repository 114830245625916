.target-button {
  background: linear-gradient(228.89deg, #0a6789 1.12%, #2da5c0 100%);
  border-radius: 8px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.125em;
  color: white;
  position: relative;
  cursor: pointer;
}

.target-button:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.target-button:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

.target-button-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 10px);
  padding: 10px 5px 10px 5px;
}

.target-button-icon {
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 1024px) {
  .target-button {
    width: 196px;
    height: 40px;
  }

  .contracted-target-button {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-button {
    width: 196px;
    height: 40px;
  }

  .contracted-target-button {
    width: 34px;
  }
}

@media only screen and (min-width: 1386px) {
  .target-button {
    width: 216px;
    height: 50px;
  }

  .contracted-target-button {
    width: 45px;
  }
}
