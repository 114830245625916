.filter-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter-top-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.filter-bottom-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 10px;
  padding: 9px 0px 0px 0px;
}

.filter-list {
  display: flex;
  height: 35px;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.filter-list:last-child {
  margin-bottom: 10px;
}

.filter-select {
  flex-grow: 1;
}

.filter-data-select {
  flex-grow: 1;
}
