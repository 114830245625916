.select-attribute-button {
  color: #747475;
  border: 0px;
  cursor: pointer;
  font-weight: 700px;
  font-size: 0.75em;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .select-attribute-button {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .select-attribute-button {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1386px) {
  .select-attribute-button {
    font-size: 1.2em;
  }
}
