.party-summary-widget-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #27262e;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
}

@media only screen and (max-width: 1024px) {
  .party-summary-widget-text {
    font-size: 0.75em;
    line-height: 0.75em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .party-summary-widget-text {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .party-summary-widget-text {
    font-size: 1.2em;
    line-height: 27px;
  }
}
