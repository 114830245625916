.intro-page-container {
  min-height: 542px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
}

.intro-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
}

.blue-dots-up {
  position: absolute;
  background-image: url(../../../resources/images/blue-dots.svg);
}

.black-dots-left {
  position: absolute;
  background-image: url(../../../resources/images/black-dots.svg);
}

.blue-dots-down {
  position: absolute;
  background-image: url(../../../resources/images/blue-dots.svg);
}

@media only screen and (max-width: 1024px) {
  .intro-content {
    width: 298px;
    height: 251px;
  }

  .intro-image-pane {
    display: None;
  }

  .blue-dots-down {
    width: 199px;
    height: 40px;
    right: 17%;
    bottom: 0px;
  }

  .black-dots-left {
    display: None;
  }

  .blue-dots-up {
    width: 145px;
    height: 34px;
    right: 0%;
    top: 0%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .intro-content {
    width: 298px;
    height: 251px;
  }

  .intro-image-pane {
    width: 328px;
    height: 328px;
  }

  .blue-dots-down {
    width: 199px;
    height: 90px;
    right: 17%;
    bottom: 0px;
  }

  .black-dots-left {
    width: 124px;
    height: 138px;
    left: 0%;
    top: 41%;
  }

  .blue-dots-up {
    width: 145px;
    height: 114px;
    right: 0%;
    top: 0%;
  }
}

@media only screen and (min-width: 1386px) {
  .intro-content {
    width: 373px;
    height: 314px;
  }

  .intro-image-pane {
    width: 468px;
    height: 468px;
  }

  .blue-dots-down {
    width: 199px;
    height: 90px;
    right: 17%;
    bottom: 0px;
  }

  .black-dots-left {
    width: 124px;
    height: 138px;
    left: 0%;
    top: 41%;
  }

  .blue-dots-up {
    width: 145px;
    height: 114px;
    right: 0%;
    top: 0%;
  }
}
