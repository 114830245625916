.home-banner {
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: center;
  min-width: 0px;
  min-height: 0px;
  overflow: hidden;
  margin-top: 12px;
}

.home-banner-sub-container {
  overflow: hidden;
  height: 100%;
  transition: opacity 750ms ease-out;
}

.home-banner-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  gap: 15px;
}

.home-banner-right {
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .home-banner {
    height: 300px;
    overflow: hidden;
  }

  .home-banner-left {
    flex-grow: 1;
  }

  .home-banner-right {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .home-banner {
    min-height: 300px;
    overflow: hidden;
  }

  .home-banner-image {
    height: 100%;
  }

  .home-banner-left {
    width: 180px;
  }

  .home-banner-sub-container {
    min-height: 300px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .home-banner {
    height: 211px;
    overflow: hidden;
  }

  .home-banner-sub-container {
    min-height: 211px;
  }

  .home-banner-image {
    width: 280px;
  }

  .home-banner-left {
    width: 220px;
  }
}

@media only screen and (min-width: 1386px) {
  .home-banner {
    height: 260px;
    overflow: hidden;
  }

  .home-banner-sub-container {
    min-height: 260px;
  }

  .home-banner-image {
    width: 430px;
  }

  .home-banner-left {
    width: 280px;
  }
}
