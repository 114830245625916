.waiting-payment-status-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: #bf9730;
}

.in-progress-status-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: #22c5c5;
}

.completed-status-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: #22c55e;
}

.expired-status-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: #bf3030;
}
