.a4-paper {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #f5f5f5 0%, #ffffff 85%);
}

.a4-paper-inside {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.image-pane {
  background-color: white;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.field-pane {
  cursor: pointer;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

@media print {
  .print-hidden {
    display: none;
  }
}
