@media only screen and (max-width: 1024px) {
  .choropleth-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: calc(100% - 16px);
    min-width: 0px;
    gap: 24px;
    margin: 16px 8px;
  }

  .choropleth-settings-select {
    flex-grow: 0;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-data-select {
    flex-grow: 1;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-line-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .choropleth-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: calc(100% - 32px);
    min-width: 0px;
    gap: 24px;
    margin: 16px 16px;
  }

  .choropleth-settings-select {
    flex-grow: 1;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-data-select {
    flex-grow: 1;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-line-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }
}

@media only screen and (min-width: 1386px) {
  .choropleth-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    flex-grow: 1;
    min-width: 0px;
    gap: 24px;
    margin: 16px 16px;
  }

  .choropleth-settings-select {
    flex-grow: 1;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-data-select {
    flex-grow: 1;
    flex-basis: 0px;
    min-width: 0px;
  }

  .choropleth-settings-line-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
  }
}
