.notebook-input-label-container {
  display: flex;
  flex-direction: column;
  height: 37px;
}

.notebook-input-text {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  width: 100%;
  height: 100%;
  border: 0px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #d5dae1;
  border-radius: 8px;
  color: #27262e;
}

.notebook-input-text-invalid {
  border: 1px solid #fc6969;
}

.notebook-input-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  color: #747475;
}

.notebook-input-label-invalid {
  color: #fc6969;
}

.notebook-input-text::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
  font-size: 1em;
}

.notebook-input-text:active,
.notebook-input-text:focus {
  outline: none;
  border: 1px solid #d5dae1;
}

@media only screen and (max-width: 1024px) {
  .notebook-input-text {
    padding: 5px 10px;
    font-weight: 400;
    font-size: 1em;
    line-height: 0.5em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .notebook-input-text {
    padding: 5px 10px;
    font-weight: 400;
    font-size: 1em;
    line-height: 0.5em;
  }
}

@media only screen and (min-width: 1386px) {
  .notebook-input-text {
    padding: 5px 10px;
    font-weight: 400;
    font-size: 1em;
    line-height: 0.8em;
  }
}
