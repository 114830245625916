.create-order-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 48px);
  max-width: 320px;
  min-height: 130px;
  gap: 8px;
  animation: appear 250ms ease-in forwards;
}

.create-order-modal-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.create-order-modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  color: #27262e;
}

.create-order-modal-top-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  color: #747475;
}

.create-order-modal-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.modal-large-icon {
  width: 100px;
  height: 100px;
}
