.modal-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  border: 0px;
  cursor: pointer;
}

.modal-button-primary {
  background: #0a6789;
  color: #f0fdf4;
}

.modal-button-primary:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.modal-button-primary:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

.modal-button-secondary {
  color: #0a6789;
  border: 1px solid #0a6789;
  background: #f0fdf4;
}

.modal-button-secondary:hover {
  color: #0a6789;
  border: 1px solid #0a6789;
}

.modal-button-secondary:active {
  color: #0a6789;
  border: 1px solid #0a6789;
}

.modal-button:disabled {
  background-color: hsl(142, 5%, 45%);
}

@media only screen and (max-width: 1024px) {
  .modal-button {
    padding: 9px 7px;
    font-size: 0.7em;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .modal-button {
    padding: 9px 7px;
    font-size: 0.7em;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1386px) {
  .modal-button {
    padding: 10px 9px;
    font-size: 0.8em;
    line-height: 1em;
  }
}
