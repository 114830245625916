.home-analysis {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 12px;
  justify-content: center;
}

.home-analysis-shortcut {
  flex-grow: 1;
  flex-basis: 0px;
  height: 100%;
  max-width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home-analysis-shortcut-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: 100%;
  width: calc(100% - 0px);
  height: calc(100% - 5px);
  margin-bottom: 5px;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

.analysis-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.analysis-image {
  transition: transform 0.2s ease-in-out;
}

.analysis-image:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 480px) {
  .home-analysis {
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 0;
  }

  .home-analysis-shortcut {
    flex-basis: calc(50% - 10px);
    width: calc(50% - 10px);
    max-width: 175px;
  }

  .home-analysis-shortcut-image {
    border-radius: 8px;
  }

  .home-analysis-shortcut {
    max-height: 175px;
  }

  .home-analysis-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .home-analysis {
    flex-wrap: wrap;
  }

  .home-analysis-shortcut-image {
    border-radius: 8px;
  }

  .home-analysis-shortcut {
    max-height: 175px;
  }

  .home-analysis-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .home-analysis-shortcut-image {
    border-radius: 8px;
  }

  .home-analysis-shortcut {
    max-height: 175px;
  }

  .home-analysis-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 1386px) {
  .home-analysis-shortcut {
    max-height: 225px;
  }

  .home-analysis-shortcut-image {
    max-height: 200px;
  }
}
