.target-selection-button {
  background: #39383e;
  width: 100%;
  border-radius: 8px;
  height: 54px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  color: white;
  position: relative;
  cursor: pointer;
}

.unselected-target-selection-button {
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 54px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2em;
  color: #d1d1d1;
  background-color: #f7f7f7;
  border: 0px;
  position: relative;
  cursor: pointer;
}

.target-selection-button:hover {
  background: linear-gradient(228.89deg, #1f1e22 1.12%, #1f1e22 100%);
}

.target-selection-button:active {
  background: linear-gradient(228.89deg, #1f1e22 1.12%, #1f1e22 100%);
}
