.map-button {
  background: #ffffff;
  cursor: pointer;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.map-button:hover {
  background: #f1f1f1;
}

.map-button:active {
  background: #ffffff;
  transform: translateY(2px);
  box-shadow: inset 1px 1px 0px 0px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1024px) {
  .map-button {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .map-button {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}

@media only screen and (min-width: 1386px) {
  .map-button {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }
}
