.menu-item-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "salt" on;
  color: #bbc3cf;
}

.menu-item-text-selected {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-feature-settings: "salt" on;
  color: #2da5c0;
}

@media only screen and (max-width: 1024px) {
  .menu-item-text {
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.25em;
  }

  .menu-item-text-selected {
    font-size: 0.8em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .menu-item-text {
    font-size: 0.8em;
    line-height: 1.25em;
  }

  .menu-item-text-selected {
    font-size: 0.8em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1386px) {
  .menu-item-text {
    font-size: 0.9em;
    line-height: 1.3em;
  }

  .menu-item-text-selected {
    font-size: 0.9em;
    line-height: 1.3em;
  }
}
