.party-profile-summary-container {
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.party-profile-summary-container-line {
  display: flex;
  flex-direction: row;
  border: 0px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.party-profile-summary-widget {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border: 0px;
  height: 100%;
  flex-grow: 1;
  border-radius: 12px;
  overflow: hidden;
  flex-grow: 1;
  min-width: 0px;
  flex-basis: 50%;
}

@media only screen and (max-width: 1024px) {
  .party-profile-summary-container {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .party-profile-summary-container {
    gap: 8px;
  }

  .party-profile-summary-container-line {
    gap: 8px;
  }

  .party-profile-summary-widget {
    height: calc(100% - 16px);
    padding: 8px;
  }
}
