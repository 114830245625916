.dashboard-page-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
}

.dashboard-left-pane {
  display: flex;
  height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
}

.dashboard-right-pane {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-basis: 0px;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
}

.dashboard-top-widget-container {
  display: flex;
  flex-direction: row;
}

.dashboard-filter {
  display: flex;
  flex-basis: 0px;
  min-width: 0px;
}

.dashboard-map-container {
  position: relative;
}

.dashboard-fullscreen-map-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1090;
}

@media only screen and (max-width: 1024px) {
  .dashboard-page-container {
    overflow: hidden;
  }

  .dashboard-left-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .dashboard-left-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(-100vw);
    transition: transform 0.15s;
  }

  .dashboard-right-pane {
    width: 100%;
    flex-basis: 100%;
    transform: translateX(0%);
    transition: transform 0.15s;
  }

  .dashboard-right-pane-hidden {
    width: 0%;
    flex-basis: 0%;
    transform: translateX(100vw);
    transition: transform 0.15s;
  }

  .dashboard-filter {
    width: calc(100% - 12px);
    padding: 6px 6px 8px 6px;
  }

  .dashboard-map-container {
    width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    padding: 6px 6px 11px 6px;
    flex-grow: 1;
    flex-basis: 0px;
  }

  .dashboard-top-widget-container {
    width: calc(100% - 12px);
    height: 75px;
    padding: 6px 6px 6px 6px;
    gap: 16px;
  }

  .dashboard-bottom-widget-container {
    width: calc(100% - 12px);
    flex-basis: 0px;
    flex-grow: 1;
    padding: 6px 6px 11px 6px;
    min-height: 0px;
  }

  .dashboard-fullscreen-map-container {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    z-index: 1090;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .dashboard-left-pane {
    flex-basis: 0px;
    flex-grow: 2;
  }

  .dashboard-right-pane {
    flex-basis: 430px;
    width: 430px;
  }

  .dashboard-filter {
    width: calc(100% - 38px);
    max-width: calc(100% - 38px);
    padding: 6px 6px 8px 32px;
  }

  .dashboard-map-container {
    width: calc(100% - 38px);
    max-width: calc(100% - 38px);
    padding: 6px 6px 11px 32px;
    flex-grow: 1;
    flex-basis: 0px;
  }

  .dashboard-top-widget-container {
    width: calc(100% - 24px);
    height: 75px;
    padding: 6px 18px 6px 6px;
    gap: 16px;
  }

  .dashboard-bottom-widget-container {
    width: calc(100% - 24px);
    flex-basis: 0px;
    flex-grow: 1;
    padding: 6px 18px 11px 6px;
    min-height: 0px;
  }
}

@media only screen and (min-width: 1386px) {
  .dashboard-left-pane {
    flex-basis: 0px;
    flex-grow: 2;
  }

  .dashboard-right-pane {
    flex-basis: 500px;
    width: 500px;
  }

  .dashboard-filter {
    width: calc(100% - 44px);
    max-width: calc(100% - 44px);
    padding: 12px 12px 8px 32px;
  }

  .dashboard-map-container {
    width: calc(100% - 44px);
    max-width: calc(100% - 44px);
    padding: 8px 12px 21px 32px;
    flex-grow: 1;
    flex-basis: 0px;
  }

  .dashboard-top-widget-container {
    width: calc(100% - 48px);
    height: 82px;
    padding: 12px 36px 12px 12px;
    gap: 24px;
  }

  .dashboard-bottom-widget-container {
    width: calc(100% - 48px);
    flex-basis: 0px;
    flex-grow: 1;
    padding: 12px 36px 21px 12px;
    min-height: 0px;
  }
}
