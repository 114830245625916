.profile-orders-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px;
}

.profile-orders-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  width: calc(100% - 32px);
  padding: 0px 16px;
  gap: 5px;
}

.profile-orders-item {
  flex-grow: 1;
  flex-basis: 0px;
}

.profile-orders-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
  width: 100%;
  gap: 5px;
  min-width: 700px;
  position: sticky;
  top: 0px;
  padding-bottom: 10px;
  padding-left: 8px;
  background-color: #fff;
}

.profile-orders-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #27262e;
  padding: 5px 10px;
  border-radius: 6px;
  min-width: 700px;
}

.profile-orders-content:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.profile-orders-item-see-details {
  display: flex;
  justify-content: flex-start;
  color: #438fbf;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 20px;
}

@media only screen and (max-width: 1024px) {
  .profile-orders-container {
    padding-top: 16px;
    padding-bottom: 0px;
  }

  .profile-orders-container {
    padding-top: 16px;
  }

  .profile-orders-header {
    font-size: 0.75em;
    line-height: 1em;
  }

  .profile-orders-content {
    font-size: 0.75em;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .profile-orders-header {
    font-size: 0.75em;
    line-height: 1em;
  }

  .profile-orders-content {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}

@media only screen and (min-width: 1386px) {
  .profile-orders-header {
    font-size: 0.75em;
    line-height: 1em;
  }

  .profile-orders-content {
    font-size: 1em;
    line-height: 1.2em;
  }
}
