.shop-signup-form {
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shop-signup-terms-acceptance {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  gap: 8px;
}

.shop-signup-social-login {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.shop-signup-signup {
  margin-top: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.shop-signup-input,
.shop-signup-input:active,
.shop-signup-input:focus {
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.shop-signup-input-password,
.shop-signup-input-password:active,
.shop-signup-input-password:focus {
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.shop-signup-title {
  color: #0a6789;
}

.shop-signup-subtitle {
  color: #0a6789;
}

.shop-signup-label {
  color: #0a6789;
}

.shop-signup-signup-text {
  color: #0a6789;
}

@media only screen and (max-width: 1024px) {
  .shop-signup-logo {
    margin-bottom: 16px;
  }

  .shop-signup-title {
    margin-bottom: 8px;
  }

  .shop-signup-subtitle {
    margin-bottom: 16px;
  }

  .shop-signup-label {
    margin-bottom: 4px;
  }

  .shop-signup-input {
    margin-bottom: 8px;
  }

  .shop-signup-input-password {
    margin-bottom: 8px;
  }

  .shop-signup-form {
    width: 260px;
  }

  .shop-signup-terms-acceptance {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .shop-signup-shop-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-signup-logo {
    margin-bottom: 16px;
  }

  .shop-signup-title {
    margin-bottom: 8px;
  }

  .shop-signup-subtitle {
    margin-bottom: 16px;
  }

  .shop-signup-label {
    margin-bottom: 4px;
  }

  .shop-signup-input {
    margin-bottom: 8px;
  }

  .shop-signup-input-password {
    margin-bottom: 8px;
  }

  .shop-signup-form {
    width: 300px;
  }

  .shop-signup-shop-signup-text {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-signup-logo {
    margin-bottom: 22px;
  }

  .shop-signup-title {
    margin-bottom: 16px;
  }

  .shop-signup-subtitle {
    margin-bottom: 26px;
  }

  .shop-signup-label {
    margin-bottom: 6px;
  }

  .shop-signup-input {
    margin-bottom: 24px;
  }

  .shop-signup-input-password {
    margin-bottom: 16px;
  }

  .shop-signup-form {
    width: 420px;
  }

  .shop-signup-shop-signup-text {
    font-size: 1em;
  }
}
