.shop-cart-item-container {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.shop-cart-item-image {
  width: 84px;
}

.shop-cart-item-details {
  flex-grow: 1;
}

.shop-cart-item-price {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  color: #27262e;
  width: 84px;
}

.shop-cart-item-title-text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  color: #0a6789;
  margin-bottom: 10px;
}

.shop-candidate-cart-item-photo {
  width: 69px;
  height: 69px;
  border-radius: 8px;
  object-fit: cover;
}

.shop-cart-item-details-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #607e89;
}

.shop-cart-separator {
  width: 100%;
  border: 0.5px solid #dcdcdc;
}

.shop-candidate-cart-item-flag {
  width: 69px;
  height: 69px;
  border-radius: 8px;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .shop-cart-item-image {
    display: none;
  }
}
