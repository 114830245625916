.logout-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 48px);
  max-width: 320px;
  min-height: 130px;
  gap: 8px;
}

.logout-modal-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
