.retreat-button {
  position: relative;
  top: 58px;
  transition: all 0.15s;
  cursor: pointer;
  z-index: 1070;
}

.retreat-button-arrow {
  position: relative;
  top: 50px;
  transition: all 0.15s;
  cursor: pointer;
  z-index: 1070;
}

.contracted-retreat {
  position: relative;
  top: 58px;
  transition: all 0.15s;
  cursor: pointer;
  z-index: 1070;
}

.contracted-retreat-arrow {
  position: relative;
  top: 50px;
  transition: all 0.15s;
  cursor: pointer;
  z-index: 1070;
}

@media only screen and (max-width: 1024px) {
  .retreat-button {
    left: 239px;
  }

  .retreat-button-arrow {
    left: 223px;
  }

  .contracted-retreat {
    left: 0px;
    display: none;
  }

  .contracted-retreat-arrow {
    left: 0px;
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .retreat-button {
    left: 239px;
  }

  .retreat-button-arrow {
    left: 223px;
  }

  .contracted-retreat {
    left: 59px;
  }

  .contracted-retreat-arrow {
    left: 43px;
  }
}

@media only screen and (min-width: 1386px) {
  .retreat-button {
    left: 279px;
  }

  .retreat-button-arrow {
    left: 263px;
  }

  .contracted-retreat {
    left: 89px;
  }

  .contracted-retreat-arrow {
    left: 74px;
  }
}
