.proprities-selector-list-container {
  display: flex;
  height: calc(100% - 170px);
  max-height: calc(100% - 170px);
  width: 100%;
}

.proprities-selector-list {
  display: grid;
  margin: auto;
  grid-gap: 10px;
  max-width: 100%;
}

@media only screen and (max-width: 1024px) {
  .proprities-selector-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .proprities-selector-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1386px) {
  .proprities-selector-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
