.intro-title-text {
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.8em;
  color: #2a3342;
}

@media only screen and (max-width: 1024px) {
  .intro-title-text {
    font-size: 1.5em;
    line-height: 2em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .intro-title-text {
    font-size: 1.8em;
    line-height: 2.3em;
  }
}

@media only screen and (min-width: 1386px) {
  .intro-title-text {
    font-size: 36px;
    line-height: 44px;
  }
}
