.menu {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 42px);
  position: relative;
  flex-direction: column;
  animation: appear 250ms ease-in forwards;
  left: 20px;
}

.contracted-menu {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 42px);
  position: relative;
  flex-direction: column;
  left: 20px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.dataelege-logo {
  width: 125px;
  height: 40px;
}

.contracted-dataelege-logo {
  width: 35px;
  height: 40px;
}

.menu-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.menu-entry:hover {
  background-color: #f1f1f1;
}

.menu-entry-group {
  display: flex;
  flex-direction: column;
}

.menu-separator {
  background-color: #d9d9d9;
  height: 4px;
  width: 35px;
  border: 0px;
  margin: 0px;
  border-style: solid;
}

@media only screen and (max-width: 1024px) {
  .menu {
    left: 20px;
  }

  .contracted-menu {
    opacity: 0;
    display: block;
    width: 0px;
    left: 20px;
    top: 25px;
  }

  .contracted-menu-entry {
    opacity: 0;
    display: block;
    left: -290px;
    position: relative;
  }

  .menu-items {
    gap: 10px;
  }

  .dataelege-logo {
    width: 113px;
    height: 40px;
  }

  .menu-entry {
    width: 190px;
    gap: 18px;
    padding: 5px 5px 5px 5px;
  }

  .menu-entry-img {
    width: 15px;
    height: 15px;
  }

  .menu-separator {
    height: 4px;
    width: 35px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .menu {
    left: 20px;
  }

  .contracted-menu {
    left: 20px;
  }

  .menu-items {
    gap: 10px;
  }

  .dataelege-logo {
    width: 141px;
    height: 50px;
  }

  .menu-entry {
    width: 190px;
    gap: 18px;
    padding: 5px 5px 5px 5px;
  }

  .contracted-menu-entry {
    width: 17px;
  }

  .menu-entry-img {
    width: 15px;
    height: 15px;
  }

  .menu-separator {
    height: 4px;
    width: 30px;
    border: 0px;
    margin: 0px 0px 10px 0px;
  }
}

@media only screen and (min-width: 1386px) {
  .menu {
    left: 35px;
  }

  .contracted-menu {
    left: 35px;
  }

  .menu-items {
    gap: 18px;
  }

  .dataelege-logo {
    width: 170px;
    height: 60px;
  }

  .contracted-dataelege-logo {
    width: 53px;
    height: 60px;
  }

  .menu-entry {
    width: 230px;
    padding: 9px 5px 9px 5px;
    gap: 18px;
    border-radius: 5px;
  }

  .contracted-menu-entry {
    width: 22px;
  }

  .menu-entry-img {
    width: 22px;
  }

  .menu-entry-container {
    height: 18px;
  }

  .menu-separator {
    height: 4px;
    width: 35px;
    margin: 0px 0px 15px 0px;
    border: 0px;
  }
}
