.popup {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: fit-content;
  width: fit-content;
  padding: 0px;
  margin: 0px;
}

.popup-content {
  position: absolute;
  z-index: 1080;
  visibility: hidden;
  width: fit-content;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.popup .popup-show {
  visibility: visible;
  -webkit-animation: fadeIn 0.15s;
  animation: fadeIn 0.15s;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

.popup-menu-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 20px);
  gap: 18px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.popup-menu-entry:hover {
  background-color: #f1f1f1;
}

.popup-menu-options {
  height: fit-content;
  max-height: 320px;
  background-color: white;
}

.popup-menu-search-container {
  padding: 8px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@media only screen and (max-width: 1024px) {
  .popup-content {
    width: 120px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .popup-content {
    width: 200px;
  }
}

@media only screen and (min-width: 1386px) {
  .popup-content {
    width: 250px;
  }
}
