.candidate-search-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 85px;
  width: calc(100% - 24px);
  padding: 0px 8px 0px 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 12px;
  gap: 5px;
}

.candidate-search-item-container:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.candidate-search-item-candidate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-grow: 0.5;
  flex-basis: 0px;
}

.candidate-search-item-photo {
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-radius: 22px;
  object-fit: cover;
}

.candidate-search-item-details {
  flex-grow: 1;
  font-family: "Poppins";
  font-style: normal;
}

.candidate-search-item-name {
  flex-grow: 1;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.3125em;
  color: #27262e;
}

.candidate-search-item-candidature-details {
  flex-grow: 2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1em;
  color: #747475;
}

.candidate-search-item-elections {
  flex-grow: 0.1;
  flex-basis: 0px;
}

.candidate-search-item-elections-content {
  width: 32px;
  height: 32px;
  background: #ececec;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 27px;
  color: #757474;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate-search-item-wins {
  flex-grow: 0.1;
  flex-basis: 0px;
}

.candidate-search-item-wins-content {
  width: 32px;
  height: 32px;
  background: #cdffed;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 27px;
  color: #31ac80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate-search-item-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 0.3;
  flex-basis: 0px;
}

@media only screen and (max-width: 1024px) {
  .candidate-search-item-elections {
    display: none;
  }

  .candidate-search-item-wins {
    display: none;
  }

  .candidate-search-item-candidate {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
