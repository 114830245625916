.target-form-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 82px);
  height: calc(100% - 62px);
  max-width: 1000px;
  margin: 0px auto;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
}

.target-form-container {
  flex-grow: 1;
  max-height: calc(100% - 64px);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.target-form-title {
  font-family: Poppins;
  font-weight: 500;
  text-align: center;
}

.target-form-step {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #747475;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  .target-form-title {
    font-size: 1em;
    line-height: 1.25em;
  }

  .target-form-step {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-form-title {
    font-size: 1em;
    line-height: 1.3em;
  }

  .target-form-step {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .target-form-title {
    font-size: 1.2em;
    line-height: 1.5em;
  }

  .target-form-step {
    font-size: 1em;
    line-height: 1.2em;
  }
}
