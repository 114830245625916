.widget-item-container {
  width: calc(100% - 30px);
  height: 34px;
  background-color: #ffffff;
  padding: 4px 15px 4px 5px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.widget-item-text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  flex-wrap: nowrap;
}

.widget-bar-container {
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
}

.widget-bar {
  display: flex;
  width: 100%;
  height: 34px;
  background: #ededed;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.widget-bar-item-progress {
  position: relative;
  top: 0.45em;
  display: flex;
  height: 34px;
  min-height: 34px;
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
  flex-direction: column;
  justify-content: center;
  width: 0px;
  transition: width 1s ease-out;
}

.widget-bar-item-text {
  position: relative;
  top: -1.3em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  font-feature-settings: "salt" on;
  color: #073a4b;
  margin-left: 15px;
  z-index: 1010;
}
