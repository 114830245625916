.intro-pane {
  display: flex;
  flex-direction: column;
}

.intro-title-text {
  margin-bottom: 15px;
}

.content-text {
  margin-bottom: 15px;
}
