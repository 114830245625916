.shop-heading1 {
  font-family: Poppins;
  font-weight: 500;
  color: #0a6789;
  text-align: center;
  max-width: 100%;
}

.shop-heading2 {
  font-family: Poppins;
  font-weight: 400;
  color: #0a6789;
  text-align: center;
  max-width: 100%;
}

@media only screen and (max-width: 1024px) {
  .shop-heading1 {
    font-size: 1.4em;
  }

  .shop-heading2 {
    font-size: 0.7em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-heading1 {
    font-size: 1.6em;
  }

  .shop-heading2 {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .shop-heading1 {
    font-size: 1.8em;
  }

  .shop-heading2 {
    font-size: 1em;
  }
}
