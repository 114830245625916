.target-input {
  background: #ffffff;
  border: 1px solid #d5dae1;
  border-radius: 4px;
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #27262e;
  margin-bottom: 5px;
  width: calc(100% - 28px);
  text-align: center;
}

.target-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
}

.target-input:focus {
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .target-input {
    padding: 3px 14px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-input {
    padding: 5px 14px;
    font-size: 1em;
    font-weight: 400;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1386px) {
  .target-input {
    padding: 10px 14px;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1em;
  }
}
