.save-questionaire-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100vw - 48px);
  max-width: 320px;
  min-height: 130px;
  gap: 8px;
  animation: appear 250ms ease-in forwards;
}

.save-questionaire-modal-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.save-questionaire-modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  color: #27262e;
}

.save-questionaire-modal-top-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  color: #747475;
}

.save-questionaire-modal-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.save-questionaire-modal-input {
  font-family: Poppins;
  border: none;
  width: 100%;
  text-align: center;
  color: #747475;
  font-size: 1em;
  border-bottom: 1px solid #747475;
}

.save-questionaire-modal-input-button {
  width: 100%;
}

.save-questionaire-modal-input:focus {
  outline: none;
}

.modal-large-icon {
  width: 100px;
  height: 100px;
}
