.target-widget-item-container {
  width: calc(100% - 30px);
  height: 34px;
  background-color: #ffffff;
  padding: 4px 15px 4px 5px;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.target-widget-item-text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  flex-basis: 0px;
  min-width: 0px;
  flex-wrap: nowrap;
}

.target-widget-input-item-text {
  position: relative;
  top: -1.3em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.2em;
  letter-spacing: 0.1em;
  font-feature-settings: "salt" on;
  color: #073a4b;
  margin-left: 15px;
  z-index: 1010;
}

.target-widget-input-container {
  flex-grow: 2;
  flex-basis: 0px;
  min-width: 0px;
}

.target-widget-input {
  font-family: Poppins;
  border: none;
  width: 100%;
  text-align: center;
  color: #073a4b;
  font-size: 1em;
  border-bottom: 1px solid #073a4b;
}

.target-widget-input:focus {
  outline: none;
}

.target-widget-input-icon {
  position: relative;
  top: 10px;
  left: 18px;
  cursor: pointer;
}

.target-widget-input-container {
  position: relative;
  display: flex;
  flex-direction: row;
}
