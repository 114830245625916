.delete-target-button {
  background: #b81007;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 15px;
  border: 0px;
  width: 30px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  cursor: pointer;
}

.delete-target-button-icon {
  margin-top: 5px;
  color: white;
}

.delete-target-button:hover {
  background: #ee2c22;
  transform: scale(1.1, 1.1);
}
