.select-strategy-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.select-strategy-button-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select-strategy-info-text {
  font-family: Poppins;
  color: #747475;
  font-size: 1em;
  text-align: center;
}
