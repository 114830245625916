.shop-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
  .shop-page {
    width: calc(100% - 10px);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .shop-page {
    width: calc(100% - 16px);
  }
}

@media only screen and (min-width: 1386px) {
  .shop-page {
    width: calc(100% - 20px);
  }
}
