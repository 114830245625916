.settings-switch-outer-container {
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
}

.settings-switch {
  position: relative;
  display: inline-block;
}

.settings-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.settings-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #bbc3cf;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.settings-slider:before {
  position: absolute;
  content: "";
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .settings-slider {
  background: linear-gradient(228.89deg, #0a6789 1.12%, #2da5c0 100%);
}

input:checked + .settings-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.settings-slider.round:before {
  border-radius: 50%;
}

.settings-switch {
  width: 60px;
  height: 36px;
}

.settings-slider.round {
  border-radius: 30px;
}

.settings-slider:before {
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 5px;
}

input:checked + .settings-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
