.target-mini-select-outer-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-basis: 0px;
  min-width: 0px;
  display: flex;
  width: 100%;
  height: 10px;
  align-items: center;
}

.target-mini-select-container {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.6em;
  height: 14px;
  max-width: 100%;
  min-width: 0px;
  flex-basis: 0px;
  color: #27262e;
}

.target-mini-select {
  border: none;
  padding: 0px 0px 0px 10px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #bbc0c9;
}

.target-mini-select::after {
  position: absolute;
  right: 10px;
  top: 50%;
  background-color: transparent;
  transform: translateY(-50%);
  pointer-events: none;
  font-family: Poppins;
  font-style: normal;
}

.target-mini-select-options {
  background-color: white;
}

.target-mini-select-menu {
  z-index: 1080 !important;
}

.target-mini-select-placeholder {
  color: #bbc0c9;
  opacity: 1;
  overflow: hidden;
}

.target-mini-select-line {
  flex-grow: 2;
  border: none;
  margin-left: 5px;
  margin-top: 11px;
  border-top: 0.2px solid #bbc0c9;
}
