.login-form-label-text {
  width: 100%;
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .login-form-label-text {
    font-size: 0.75em;
    line-height: 1.25em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .login-form-label-text {
    font-size: 1em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 1386px) {
  .login-form-label-text {
    font-size: 1em;
    line-height: 1.5em;
  }
}
