.hamburguer-button {
  width: 33px;
  height: 33px;
  color: #0a6789;
  border-radius: 8px;
  border: 0px;
  cursor: pointer;
  z-index: 1070;
  color: #fff;
  background: #2da5c0;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1024px) {
  .hamburguer-button {
    display: none;
  }
}
