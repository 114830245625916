.add-filter-button {
  color: #ffffff;
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
  border: 0px;
  cursor: pointer;
  margin-top: 14px;
  display: flex;
  font-weight: 1200;
  font-size: 2em;
  justify-content: center;
  align-items: center;
}

.add-filter-button:hover {
  background: linear-gradient(
    90deg,
    hsl(166, 100%, 40%) 0%,
    hsl(184, 100%, 40%) 100%
  );
}

.add-filter-button:active {
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
}

@media only screen and (max-width: 1024px) {
  .add-filter-button {
    width: 36px;
    height: 36px;
    flex-basis: 36px;
    border-radius: 18px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .add-filter-button {
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    border-radius: 20px;
  }
}

@media only screen and (min-width: 1386px) {
  .add-filter-button {
    width: 46px;
    height: 46px;
    flex-basis: 46px;
    border-radius: 23px;
  }
}
