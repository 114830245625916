.banner-title-text {
  font-family: Poppins;
  font-weight: 800;
  background: linear-gradient(96.92deg, #2da5c0 0%, #073a4b 85.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #ffde77;
}

@media only screen and (max-width: 1024px) {
  .banner-title-text {
    font-size: 1em;
    line-height: 1em;
    letter-spacing: -0.02em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .banner-title-text {
    font-size: 1.3em;
    line-height: 1.3em;
    letter-spacing: -0.02em;
  }
}

@media only screen and (min-width: 1386px) {
  .banner-title-text {
    font-size: 1.6em;
    line-height: 1.6em;
    letter-spacing: -0.02em;
  }
}
