.target-form-button {
  background: #22c55e;
  width: 100%;
  border-radius: 8px;
  height: 34px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  color: white;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.target-form-button-disabled {
  background: #d2dfd7;
  width: 100%;
  border-radius: 8px;
  height: 34px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  color: #d1d1d1;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;
}

.target-form-button:hover {
  background: linear-gradient(228.89deg, #40ec7f 1.12%, #0af861 100%);
}

.target-form-button:active {
  background: linear-gradient(228.89deg, #40ec7f 1.12%, #0af861 100%);
}
