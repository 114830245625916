.select-target-input-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.select-target-summary-text {
  font-family: Poppins;
  color: #747475;
  font-size: 1em;
  text-align: center;
}

.target-quality-info {
  font-family: Poppins;
  color: #747475;
  font-size: 1em;
  text-align: center;
  font-weight: 700;
}
