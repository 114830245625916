.view-container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.preview-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #aeaeb0;
  box-sizing: border-box;
  padding: 55px 0px 35px 0px;
  margin-bottom: 15px;
  border-radius: 6px;
}

.notebook-view-toolbar {
  display: flex;
  background-color: #f1f1f1;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  height: 50px;
  gap: 5px;
  z-index: 10;
  padding: 0px 10px;
}

.notebook-top-view-toolbar {
  top: 0px;
  border-bottom: 2px #989797 solid;
}

.notebook-bottom-view-toolbar {
  bottom: 0px;
  height: 30px;
  border-top: 2px #989797 solid;
  justify-content: center;
}

.notebook-view-toolbar-page-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  gap: 5px;
}

.notebook-view-toolbar-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  gap: 5px;
}

.notebook-view-title {
  color: #0a6789;
  font-size: 1.5em;
}

.notebook-view-paging-input {
  text-align: center;
}

.notebook-view-selector {
  width: 110px;
}

.notebook-page-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 0.8em;
  height: 20px;
  width: 32px;
}

@media not print {
  .invisible {
    overflow: hidden;
    height: 0 !important;
  }
}

.a4-paper-container {
  margin: auto;
  height: 297mm;
  width: 210mm;
  min-height: 297mm;
  min-width: 210mm;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.notebook-view-no-notebook {
  font-family: Poppins;
  font-weight: 500;
  color: #0a6789;
  font-size: 1.5em;
}
