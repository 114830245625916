/*
============== Page Base CSS ==============
*/
html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
  background-color: #f7f7f7;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  shape-rendering: geometricPrecision;
}

#root {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
}

#alert {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: fixed;
  font-family: "Poppins";
  font-style: normal;
  bottom: 10px;
  width: 100%;
  z-index: 1100;
}

:focus {
  outline-color: #bbc0c9;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*
============== Simple Bar (react lib for scroll bar styling) CSS ==============
*/
[data-simplebar] {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.simplebar-visible::before {
  background-color: #d9d9d9;
}

/*
============== Recharts (react lib for ploting charts) CSS ==============
*/
.recharts-wrapper {
  font-family: Poppins;
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .recharts-wrapper {
    font-size: 0.65em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .recharts-wrapper {
    font-size: 0.65em;
  }
}

@media only screen and (min-width: 1386px) {
  .recharts-wrapper {
    font-size: 1em;
  }
}

/*
============== Leaflet (lib for showing maps) CSS ==============
*/

.leaflet-control-attribution {
  display: none;
}

/*
============== Fonts Definitions ==============
*/
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins"),
    url(./resources/fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins"),
    url(./resources/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins"),
    url(./resources/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins"),
    url(./resources/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins"),
    url(./resources/fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratAlternates";
  font-style: normal;
  font-weight: 100;
  src: local("MontserratAlternates"),
    url(./resources/fonts/MontserratAlternates-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratAlternates";
  font-style: normal;
  font-weight: 400;
  src: local("MontserratAlternates"),
    url(./resources/fonts/MontserratAlternates-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratAlternates";
  font-style: normal;
  font-weight: 500;
  src: local("MontserratAlternates"),
    url(./resources/fonts/MontserratAlternates-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratAlternates";
  font-style: normal;
  font-weight: 700;
  src: local("MontserratAlternates"),
    url(./resources/fonts/MontserratAlternates-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "MontserratAlternates";
  font-style: normal;
  font-weight: 900;
  src: local("MontserratAlternates"),
    url(./resources/fonts/MontserratAlternates-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Heavitas";
  src: local("Heavitas"), url(./resources/fonts/Heavitas.ttf) format("truetype");
}

/*
============== Z-Index Reference ==============
Z-indexes values set according to this reference table.
All set z-index are above 1000 because of leaflet CSS 
base z-index that goes up to 800.

  Modal: 1100
  Alert: 1100
  Modal Overlay: 1099
  Dashboard Map Fullscreen: 1090
  Tooltip: 1080
  Popup Menu: 1080
  Select: 1080
  Change Pane Button: 1070
  Hamburguer Button: 1070
  Retreat Button: 1070
  Side Bar: 1070
  Map Loading Overlay: 1060
  Map Controls: 1050
  Map Buttons: 1050
  Map Legend: 1050
  Map Top Display: 1050
  Widget Item: 1010
  Hidden Map Control Button: 0
*/
