.profile-input {
  background: #edeef4;
  border: 1px solid #d5dae1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #8896ab;
  margin-bottom: 5px;
  width: calc(100% - 28px);
}

.profile-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbc0c9;
  opacity: 1;
}

.profile-input:focus {
  outline: none;
}

@media only screen and (max-width: 1024px) {
  .profile-input {
    padding: 3px 14px;
    font-size: 0.55em;
    font-weight: 400;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .profile-input {
    padding: 5px 14px;
    font-size: 0.55em;
    font-weight: 400;
    line-height: 0.9em;
  }
}

@media only screen and (min-width: 1386px) {
  .profile-input {
    padding: 10px 14px;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1em;
  }
}
