.profile-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #22c55e;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: #f0fdf4;
  border: 0px;
  cursor: pointer;
}

.profile-button:hover {
  background: #188741;
}

.profile-button:active {
  background: #27dd69;
}

@media only screen and (max-width: 1024px) {
  .profile-button {
    padding: 8px 18px;
    font-size: 0.7em;
    line-height: 1em;
    margin-top: 8px;
  }

  .profile-button-text {
    width: 120px;
    text-align: left;
  }
}

@media only screen and (min-width: 1024px) {
  .profile-button {
    padding: 8px 18px;
    font-size: 0.7em;
    line-height: 1em;
    margin-top: 8px;
  }

  .profile-button-text {
    width: 120px;
    text-align: left;
  }
}
