.candidate-details-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 0px auto;
}

.candidate-details-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.candidate-info-label {
  font-family: Poppins;

  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;

  color: #747475;
  margin-top: 20px;
  margin-bottom: 5px;
}
