.profile-page-container {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;
  min-width: 0px;
}

.profile-top-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
}

.profile-bottom-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  min-width: 0px;
  flex-grow: 1;
  flex-basis: 0px;
}

.profile-personal-info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  gap: 8px;
  max-width: 400px;
}

.profile-personal-info-inner-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.profile-acquired-plans-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  gap: 8px;
}

.profile-acquired-plans-inner-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  min-height: 0px;
}

.profile-orders-all-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  gap: 8px;
}

.profile-orders-all-inner-container {
  display: flex;
  flex-grow: 1;
  flex-basis: 0px;
  min-height: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

@media only screen and (max-width: 1024px) {
  .profile-page-container {
    gap: 8px;
  }

  .profile-top-container {
    flex-grow: 1;
    flex-basis: 0px;
    width: calc(100% - 16px);
    margin: 0px 8px;
    gap: 8px;
  }

  .profile-bottom-container {
    flex-grow: 1;
    flex-basis: 0px;
    width: calc(100% - 16px);
    margin: 0px 8px 8px 8px;
  }

  .profile-top-container {
    flex-direction: column;
    flex-grow: 2;
    max-width: 100vw;
    min-width: 0px;
  }

  .profile-personal-info-container {
    width: 100%;
    max-width: 100%;
  }

  .profile-personal-info-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .profile-page-container {
    gap: 16px;
  }

  .profile-top-container {
    flex-basis: 260px;
    width: calc(100% - 32px);
    margin: 0px 16px;
    gap: 16px;
  }

  .profile-bottom-container {
    flex-grow: 1;
    flex-basis: 0px;
    width: calc(100% - 32px);
    margin: 0px 16px 16px 16px;
  }
}
