.analysis-name-text {
  font-family: Poppins;
  font-weight: 400;
  color: #767676;
  text-align: center;
  max-width: 100%;
  height: 34px;
}

@media only screen and (max-width: 1024px) {
  .analysis-name-text {
    font-size: 0.75em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .analysis-name-text {
    font-size: 0.75em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .analysis-name-text {
    font-size: 0.75em;
    line-height: 1em;
  }
}
