.target-checkbox {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #747475;
  display: flex;
  flex-direction: row;
  align-items: center;
  accent-color: #555555;
  gap: 8px;
  text-overflow: ellipsis;
}

.target-checkbox-pane {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.target-checkbox-input {
  width: 17px;
  height: 17px;
  margin: 0px;
}

.target-checkbox:focus {
  outline: none;
}

.target-checkbox:active {
  border: 0px;
}

@media only screen and (max-width: 1024px) {
  .target-checkbox {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .target-checkbox {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .target-checkbox {
    font-size: 1em;
    line-height: 1.5em;
  }
}
