.base-alert {
  margin: auto;
  width: calc(100% - 62px);
  max-width: 350px;
  padding: 10px 15px;
  margin: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.alert-hidden {
  display: none;
}

.alert-show-error {
  background-color: rgb(211, 47, 47);
  color: #f1f1f1;
}

.alert-show-message {
  background-color: rgb(56, 142, 60);
  color: #f1f1f1;
}

.alert-show-warn {
  background-color: rgb(245, 124, 0);
  color: #111111;
}
