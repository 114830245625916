.widget-item-text {
  font-family: Poppins;
  font-weight: 500;
  color: #27262e;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.widget-sub-item-text {
  font-family: Poppins;
  font-weight: 400;
  color: #747475;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .widget-item-text {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .widget-sub-item-text {
    font-size: 0.6em;
    line-height: 0.8em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .widget-item-text {
    font-size: 0.8em;
    line-height: 1.2em;
  }

  .widget-sub-item-text {
    font-size: 0.6em;
    line-height: 0.8em;
  }
}

@media only screen and (min-width: 1386px) {
  .widget-item-text {
    font-size: 1em;
    line-height: 1.25em;
  }

  .widget-sub-item-text {
    font-size: 0.75em;
    line-height: 1em;
  }
}
