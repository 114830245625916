@keyframes animation-logo-1 {
  0% {
    animation-timing-function: cubic-bezier(0.7806, 0.0715, 0.8998, 0.731);
    transform: translate(0, -10px);
  }
  17.5% {
    animation-timing-function: cubic-bezier(0.484, 0.3308, 0.6853, 0.6667);
    transform: translate(0, -6.18px);
  }
  27.6% {
    animation-timing-function: cubic-bezier(0.2459, 0.3343, 0.4923, 0.6717);
    transform: translate(0, 2.48px);
  }
  35.1% {
    animation-timing-function: cubic-bezier(0.1317, 0.3401, 0.311, 0.9176);
    transform: translate(0, 7.49px);
  }
  50.1% {
    animation-timing-function: cubic-bezier(0.7773, 0.0708, 0.9008, 0.735);
    transform: translate(0, 10px);
  }
  67.6% {
    animation-timing-function: cubic-bezier(0.4888, 0.331, 0.6153, 0.6674);
    transform: translate(0, 6.16px);
  }
  80% {
    animation-timing-function: cubic-bezier(0.2539, 0.3274, 0.5671, 0.676);
    transform: translate(0, -4.57px);
  }
  85% {
    animation-timing-function: cubic-bezier(0.1302, 0.3349, 0.3113, 0.9183);
    transform: translate(0, -7.47px);
  }
  100% {
    transform: translate(0, -10px);
  }
}

@keyframes animation-logo-2 {
  0% {
    animation-timing-function: cubic-bezier(0.7806, 0.0715, 0.8998, 0.731);
    transform: translate(0, -10px);
  }
  17.5% {
    animation-timing-function: cubic-bezier(0.484, 0.3308, 0.6853, 0.6667);
    transform: translate(0, -6.18px);
  }
  27.6% {
    animation-timing-function: cubic-bezier(0.2459, 0.3343, 0.4923, 0.6717);
    transform: translate(0, 2.48px);
  }
  35.1% {
    animation-timing-function: cubic-bezier(0.1317, 0.3401, 0.311, 0.9176);
    transform: translate(0, 7.49px);
  }
  50.1% {
    animation-timing-function: cubic-bezier(0.7773, 0.0708, 0.9008, 0.735);
    transform: translate(0, 10px);
  }
  67.6% {
    animation-timing-function: cubic-bezier(0.4888, 0.331, 0.6153, 0.6674);
    transform: translate(0, 6.16px);
  }
  80% {
    animation-timing-function: cubic-bezier(0.2539, 0.3274, 0.5671, 0.676);
    transform: translate(0, -4.57px);
  }
  85% {
    animation-timing-function: cubic-bezier(0.1302, 0.3349, 0.3113, 0.9183);
    transform: translate(0, -7.47px);
  }
  100% {
    transform: translate(0, -10px);
  }
}

@keyframes animation-logo-3 {
  0% {
    animation-timing-function: cubic-bezier(0.7806, 0.0715, 0.8998, 0.731);
    transform: translate(0, -10px);
  }
  17.5% {
    animation-timing-function: cubic-bezier(0.484, 0.3308, 0.6853, 0.6667);
    transform: translate(0, -6.18px);
  }
  27.6% {
    animation-timing-function: cubic-bezier(0.2459, 0.3343, 0.4923, 0.6717);
    transform: translate(0, 2.48px);
  }
  35.1% {
    animation-timing-function: cubic-bezier(0.1317, 0.3401, 0.311, 0.9176);
    transform: translate(0, 7.49px);
  }
  50.1% {
    animation-timing-function: cubic-bezier(0.7773, 0.0708, 0.9008, 0.735);
    transform: translate(0, 10px);
  }
  67.6% {
    animation-timing-function: cubic-bezier(0.4888, 0.331, 0.6153, 0.6674);
    transform: translate(0, 6.16px);
  }
  80% {
    animation-timing-function: cubic-bezier(0.2539, 0.3274, 0.5671, 0.676);
    transform: translate(0, -4.57px);
  }
  85% {
    animation-timing-function: cubic-bezier(0.1302, 0.3349, 0.3113, 0.9183);
    transform: translate(0, -7.47px);
  }
  100% {
    transform: translate(0, -10px);
  }
}

.animated-loading-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.animated-loading-logo-container-svg {
  image-rendering: auto;
  height: 7vh;
  width: auto;
}

.animated-loading-logo-text {
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75em;
  color: #a1a1a1;
  width: 80px;
  margin-top: 5px;
}

.animated-loading-logo-text:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 8px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 8px;
  }
}

/*
  transform-origin: 50% 50%;
*/
.animated-loading-logo-1 {
  animation: 900.54ms linear -0.5775s infinite normal forwards running animation-logo-1;
  transform-origin: 50% 60%;
  opacity: 10%;
}

.animated-loading-logo-2 {
  animation: 900.54ms linear -0.495s infinite normal forwards running animation-logo-2;
  transform-origin: 50% 70%;
  opacity: 10%;
}

.animated-loading-logo-3 {
  animation: 900.54ms linear -0.4125s infinite normal forwards running animation-logo-3;
  transform-origin: 50% 80%;
  opacity: 10%;
}
