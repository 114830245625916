.candidates-search-page-container {
  height: calc(100% - 16px);
  max-height: 100%;
  width: calc(100% - 64px);
  max-width: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0px 32px 16px 32px;
}

.candidates-top-search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}

.candidates-bottom-search-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  flex-grow: 1;
  min-height: 0px;
  margin-bottom: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 16px;
}

.candidates-search-result-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  min-height: 0px;
  justify-content: center;
  align-items: center;
}

.candidates-search-result-container-header {
  display: flex;
  flex-direction: row;
  flex-basis: 35px;
  justify-content: flex-start;
}

.candidates-search-result-items-list {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0px;
  min-height: 0px;
  width: 100%;
}

.candidate-search-header-candidate {
  flex-grow: 0.5;
  flex-basis: 0px;
}

.candidate-search-header-elections {
  flex-grow: 0.1;
  flex-basis: 0px;
}

.candidate-search-header-wins {
  flex-grow: 0.1;
  flex-basis: 0px;
}

.candidate-search-header-actions {
  flex-grow: 0.3;
  flex-basis: 0px;
}

@media only screen and (max-width: 1024px) {
  .candidates-search-page-container {
    height: calc(100% - 16px);
    max-height: 100%;
    width: calc(100% - 16px);
    max-width: 700px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 0px 8px 16px 8px;
  }

  .candidates-top-search-container {
    width: 100%;
  }

  .candidates-search-result-container {
    gap: 8px;
  }

  .candidate-search-header-elections {
    display: none;
  }

  .candidate-search-header-wins {
    display: none;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidates-top-search-container {
    width: 500px;
  }
  .candidates-search-result-container {
    gap: 8px;
  }
}

@media only screen and (min-width: 1386px) {
  .candidates-top-search-container {
    width: 700px;
  }

  .candidates-search-result-container {
    gap: 8px;
  }
}
