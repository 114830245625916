.change-pane-button {
  color: #ffffff;
  position: absolute;
  cursor: pointer;
  z-index: 1070;
  background-color: rgba(45, 165, 192, 0.8);
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 0px;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 50%;
  transform: translateX(50%);
  bottom: 1px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

@media only screen and (max-width: 1024px) {
  .change-pane-button-left {
    right: 10px;
  }

  .change-pane-button-right {
    left: 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .change-pane-button {
    display: none;
  }
}

@media only screen and (min-width: 1386px) {
  .change-pane-button {
    display: none;
  }
}
