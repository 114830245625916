.login-title-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8em;
  line-height: 2.3em;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .login-title-text {
    font-size: 1.2em;
    line-height: 1.75em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .login-title-text {
    font-size: 1.2em;
    line-height: 1.75em;
  }
}

@media only screen and (min-width: 1386px) {
  .login-title-text {
    font-size: 1.7em;
    line-height: 2.2em;
  }
}
