.shop-action-button {
  background: #0a6789;
  border-radius: 6px;
  height: 46px;
  min-height: 46px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75em;
  color: white;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.shop-action-button-outline {
  background: rgba(255, 255, 255, 1);
  border-radius: 6px;
  height: 46px;
  min-height: 46px;
  border: solid 1px #0a6789;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  color: #0a6789;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.shop-action-button-disable {
  background: #e9e9e9;
  border-radius: 6px;
  height: 46px;
  min-height: 46px;
  border: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75em;
  color: #cccccc;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.shop-action-button:hover {
  background: linear-gradient(228.89deg, #1888b1 1.12%, #45c5e2 100%);
}

.shop-action-button:active {
  background: linear-gradient(228.89deg, #1098c9 1.12%, #38caeb 100%);
}

.shop-action-button-outline:hover {
  background: rgba(250, 250, 250, 0.4);
}

.shop-action-button-outline:active {
  background: rgba(245, 245, 245, 1);
}
