.tooltip {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 19px;
  width: fit-content;
  padding: 0px;
  margin: 0px;
  font-family: Poppins;
}

.tooltip-content {
  position: absolute;
  z-index: 1080;
  right: 5px;
  top: -5px;
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 5px;
  font-family: Poppins;
}

.tooltip-title {
  color: #27262e;
  font-weight: 500;
}

.tooltip-text {
  color: #747475;
}

@media only screen and (max-width: 1024px) {
  .tooltip-content {
    width: fit-content;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .tooltip-content {
    width: fit-content;
  }
}

@media only screen and (min-width: 1386px) {
  .tooltip-content {
    width: fit-content;
  }
}
