.see-more-button {
  cursor: pointer;
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
  border: 0px;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625em;
  line-height: 0.875em;
  text-align: center;

  font-feature-settings: "salt" on;
  color: #073a4b;
  height: 34px;
  width: 129px;
}

.see-more-button:hover {
  background: linear-gradient(
    90deg,
    hsl(166, 100%, 40%) 0%,
    hsl(184, 100%, 40%) 100%
  );
}

.see-more-button:active {
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
}

@media only screen and (max-width: 1024px) {
  .see-more-button {
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .see-more-button {
  }
}

@media only screen and (min-width: 1386px) {
  .see-more-button {
  }
}
