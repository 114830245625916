.map-viewer-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: white;
  background-image: url(../../../../resources/images/light-map-background.png);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  position: relative;
  transition: all 0.35s;
}

.map-viewer-container-fullscreen {
  border-radius: 0px;
  transition: all 0.35s;
}

.leaflet-interactive {
  outline: none;
}

.leaflet-zoom-animated {
  outline: none;
}

.map-viewer-loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1060;
}

.map-viewer-loading-overlay-fullscreen {
  border-radius: 0px;
}

.map-polygon {
  position: absolute;
  animation: appear 750ms ease-in forwards;
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
}

@media only screen and (min-width: 1386px) {
}
