.shop-order-container {
  width: 100%;
  height: 67px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.shop-order-image {
  width: 84px;
}

.shop-order-details {
  flex-grow: 1;
}

.shop-order-price {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  color: #27262e;
  width: 84px;
  text-align: right;
}

.shop-order-date {
  font-family: Poppins;
  font-size: 0.8em;
  font-weight: 400;
  color: #27262e;
  width: 104px;
  text-align: left;
}

.shop-order-payment-method {
  font-family: Poppins;
  font-size: 0.8em;
  font-weight: 400;
  color: #27262e;
  width: 84px;
  text-align: right;
}

.shop-order-title-text {
  font-family: Poppins;
  font-size: 1em;
  font-weight: 500;
  color: #0a6789;
  margin-bottom: 15px;
  cursor: pointer;
}

.shop-order-title-text:hover {
  color: #0e88b5;
}

.shop-candidate-order-photo {
  width: 69px;
  height: 69px;
  border-radius: 8px;
  object-fit: cover;
}

.shop-order-details-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #607e89;
}

.shop-order-separator {
  width: 100%;
  border: 0.5px solid #dcdcdc;
}

.shop-candidate-order-flag {
  width: 69px;
  height: 69px;
  border-radius: 8px;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .shop-order-image {
    display: none;
  }
}
