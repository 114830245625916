.social-login-button {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #eef0f3;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #556987;
  flex-grow: 1;
  cursor: pointer;
}

.social-login-button:hover {
  background: #f0f0f0;
}

.social-login-button:active {
  background: #ffffff;
}

.social-login-button-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .social-login-button {
    font-size: 8px;
    line-height: 1em;
    height: 36px;
  }

  .social-login-icon {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .social-login-button {
    font-size: 0.625em;
    line-height: 1.25em;
    height: 36px;
  }
  .social-login-icon {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (min-width: 1386px) {
  .social-login-button {
    font-size: 0.75em;
    line-height: 1.5em;
    height: 46px;
  }
  .social-login-icon {
    width: 18px;
    height: 18px;
  }
}
