.candidate-profile-card-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border: 0px;
  border-radius: 12px;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.candidate-profile-card-sub-container {
  display: flex;
  height: auto;
  min-height: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.candidate-profile-card-image {
  width: 88px;
  height: 88px;
  border-radius: 44px;
  object-fit: cover;
}

.candidate-profile-card-details-pane {
  flex-basis: 0px;
  flex-grow: 1;
  height: 100%;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.candidate-profile-card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.candidate-profile-card-complementary-details-pane {
  margin-top: 16px;
}

.candidate-profile-card-link-map-text {
  font-family: Poppins;
  font-weight: 500;

  a {
    color: #0a6789;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1024px) {
  .candidate-profile-card-container {
    height: calc(100% - 16px);
    padding: 8px;
  }

  .candidate-profile-card-sub-container {
    gap: 12px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .candidate-profile-card-container {
    height: calc(100% - 16px);
    padding: 8px;
  }

  .candidate-profile-card-sub-container {
    gap: 12px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 1386px) {
  .candidate-profile-card-container {
    height: calc(100% - 32px);
    padding: 16px;
  }

  .candidate-profile-card-sub-container {
    gap: 12px;
    margin-left: 12px;
  }
}
