.party-profile-card-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  border: 0px;
  border-radius: 12px;
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.party-profile-card-sub-container {
  display: flex;
  height: auto;
  min-height: 0px;
  flex-grow: 1;
  flex-basis: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.party-profile-card-image-pane {
  width: 112px;
  height: 112px;
  margin: 5px;
}

.party-profile-card-details-pane {
  flex-basis: 0px;
  flex-grow: 1;
  height: 112px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.party-profile-card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.party-profile-card-logo {
  width: 112px;
  height: 112px;
  border-radius: 12px;
  object-fit: contain;
}

@media only screen and (max-width: 1024px) {
  .party-profile-card-container {
    height: calc(100% - 16px);
    padding: 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .party-profile-card-container {
    height: calc(100% - 16px);
    padding: 8px;
  }
}

@media only screen and (min-width: 1386px) {
  .party-profile-card-container {
    height: calc(100% - 32px);
    padding: 16px;
  }
}
