.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.no-data-container-svg {
  image-rendering: auto;
  height: 7vh;
  width: auto;
}

.no-data-text {
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.75em;
  color: #a1a1a1;
  margin-top: 5px;
}

.no-data-1 {
  opacity: 10%;
}

.no-data-2 {
  opacity: 10%;
}

.no-data-3 {
  opacity: 10%;
}
