.notebook-questionaire-container {
  width: calc(100% - 30px);
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 15px;
}

.notebook-questionaire-form-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 25px;
}

.notebook-questionaire-form-item-label {
  font-size: 1em;
  font-family: "Poppins";
  color: #61646b;
  font-weight: 700;
}

.notebook-questionaire-form-description {
  font-size: 0.8em;
  font-family: "Poppins";
  color: #61646b;
  font-style: italic;
  margin-top: 15px;
}

.notebook-questionaire-container-title {
  color: #0a6789;
  font-size: 1.5em;
  margin-top: 25px;
}

.notebook-questionaire-form-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 10px;
}

.notebook-questionaire-form-footer-button {
  width: 100%;
}

.notebook-questionaire-selector {
  width: 100%;
}
