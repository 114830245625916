.widget-title-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: Poppins;
  font-weight: 400;
  color: #747475;
  flex-grow: 1;
  flex-basis: 0px;
  min-width: 0px;
  overflow: hidden;
  gap: 8px;
  overflow: visible;
}

.widget-text-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
  .widget-title-text {
    font-size: 1em;
  }

  .widget-title-info-icon {
    font-size: 1.2em;
    min-width: 19px;
    height: 19px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .widget-title-text {
    font-size: 1em;
  }

  .widget-title-info-icon {
    font-size: 1.2em;
    min-width: 19px;
    height: 19px;
  }
}

@media only screen and (min-width: 1386px) {
  .widget-title-text {
    font-size: 1em;
  }

  .widget-title-info-icon {
    font-size: 1.2em;
    min-width: 19px;
    height: 19px;
  }
}
