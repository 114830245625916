.target-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 12px;
  justify-content: flex-start;
}

.target-list-shortcut {
  max-width: 100%;
  border-radius: 16px;
  position: relative;
  display: flex;
  width: 170px;
  height: 170px;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.target-list-shortcut-image-container {
  position: relative;
  top: 0px;
  left: 0px;
  max-width: 100%;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
}

.target-list-shortcut-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.target-list-shortcut-image {
  transition: transform 0.2s ease-in-out;
}

.target-list-shortcut-image:hover {
  transform: scale(1.2);
}

.target-list-shortcut-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.4em;
  text-align: center;
  color: #767676;
}

@media only screen and (max-width: 480px) {
  .home-targets {
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 0;
  }

  .home-targets-shortcut {
    flex-basis: calc(50% - 10px);
    width: calc(50% - 10px);
    max-width: 175px;
  }

  .home-targets-shortcut-image {
    border-radius: 8px;
  }

  .home-targets-shortcut {
    max-height: 175px;
  }

  .home-targets-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .home-targets {
    flex-wrap: wrap;
  }

  .home-targets-shortcut-image {
    border-radius: 8px;
  }

  .home-targets-shortcut {
    max-height: 175px;
  }

  .home-targets-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .home-targets-shortcut-image {
    border-radius: 8px;
  }

  .home-targets-shortcut {
    max-height: 175px;
  }

  .home-targets-shortcut-image {
    max-height: 150px;
  }
}

@media only screen and (min-width: 1386px) {
  .home-targets-shortcut {
    max-height: 225px;
  }

  .home-targets-shortcut-image {
    max-height: 200px;
  }
}
