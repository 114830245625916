.parties-search-page-container {
  height: calc(100% - 26px);
  max-height: 100%;
  width: calc(100% - 64px);
  padding: 0px 32px 26px 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.parties-top-search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 40px;
}

.parties-bottom-search-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  min-height: 0px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.parties-search-result-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  min-height: 0px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .parties-top-search-container {
    width: 100%;
  }

  .parties-search-result-container {
    gap: 8px;
  }

  .parties-search-page-container {
    width: calc(100% - 16px);
    padding: 0px 8px 26px 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .parties-top-search-container {
    width: 500px;
  }
  .parties-search-result-container {
    gap: 16px;
  }
}

@media only screen and (min-width: 1386px) {
  .parties-top-search-container {
    width: 700px;
  }

  .parties-search-result-container {
    gap: 24px;
  }
}
