.election-ranking-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  height: 55px;
  margin-right: 16px;
  animation: appear 0.25s ease;
}

.election-ranking-item-photo-container {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  overflow: hidden;
  background-color: #f1f1f1;
}

.election-ranking-item-photo {
  width: 44px;
  height: auto;
  opacity: 90%;
}

.election-ranking-item-details {
  flex-basis: 40%;
  font-family: "Poppins";
  font-style: normal;
  text-decoration: none;
}

.election-ranking-item-details a {
  text-decoration: none;
}

.election-ranking-item-name {
  flex-grow: 1;
  font-weight: 500;
  color: #27262e;
}

.election-ranking-item-candidature-details {
  flex-grow: 2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1em;
  color: #747475;
}

.election-ranking-item-voting {
  flex-grow: 1;
  flex-basis: 15%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.2em;
  font-feature-settings: "salt" on;
  color: #747475;
}

.election-ranking-item-relative-voting {
  flex-grow: 2;
  flex-basis: 120px;
  height: 34px;
}

.election-ranking-item-bar {
  display: flex;
  width: 100%;
  height: 34px;
  background: #ededed;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.election-ranking-item-bar-progress {
  position: relative;
  top: 0.45em;
  display: flex;
  height: 34px;
  min-height: 34px;
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
  flex-direction: column;
  justify-content: center;
  width: 0px;
  transition: width 1s ease-out;
}

.election-ranking-item-bar-text {
  position: relative;
  top: -1.3em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.2em;
  margin-left: 15px;
  font-feature-settings: "salt" on;
  color: #073a4b;
  z-index: 1010;
}

@media only screen and (max-width: 1024px) {
  .election-ranking-item-relative-voting {
    display: none;
  }

  .election-ranking-item-name {
    flex-grow: 1;
    font-weight: 400;
    font-size: 0.75em;
    color: #27262e;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .election-ranking-item-name {
    flex-grow: 1;
    font-weight: 500;
    font-size: 1em;
    color: #27262e;
  }
}

@media only screen and (min-width: 1386px) {
  .election-ranking-item-name {
    flex-grow: 1;
    font-weight: 500;
    font-size: 1em;
    color: #27262e;
  }
}
