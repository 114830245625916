.table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;
  overflow: hidden;
  font-family: "Poppins";
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.t-header {
  display: flex;
  flex-direction: column;
  height: 25px;
  color: #747475;
  border-bottom: 1px solid #aeaeb0;
  padding-bottom: 10px;
}

.t-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;
  overflow: hidden;
}

.t-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  min-height: 35px;
}

.t-line-data:nth-child(odd) {
  background-color: #fcfbfb;
}

.t-line-data:nth-child(even) {
  background-color: #ffffff;
}

.t-cell {
  overflow: hidden;
}
