.login-checkbox {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  accent-color: #22c55e;
  gap: 8px;
}

.login-checkbox-input {
  width: 17px;
  height: 17px;
  margin: 0px;
}

.login-checkbox:focus {
  outline: none;
}

.login-checkbox:active {
  border: 0px;
}

@media only screen and (max-width: 1024px) {
  .login-checkbox {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .login-checkbox {
    font-size: 0.8em;
    line-height: 1em;
  }
}

@media only screen and (min-width: 1386px) {
  .login-checkbox {
    font-size: 1em;
    line-height: 1.5em;
  }
}
