.election-number-widget-container {
  flex-grow: 1;
  flex-basis: 50%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  animation: appear 0.25s ease;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.election-number-widget-mark-container {
  display: flex;
  flex-grow: 1;
  min-height: 0px;
  justify-content: center;
  align-items: center;
}

.election-number-widget-value {
  display: flex;
  flex-basis: 0px;
  justify-content: flex-end;
}

.election-number-widget-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .election-number-widget-value {
    font-size: 1.4em;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .election-number-widget-value {
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 1386px) {
  .election-number-widget-value {
    font-size: 1.6em;
  }
}
