.map-controls-container {
  width: calc(100% - 0px);
  max-width: calc(100% - 0px);
  height: calc(100% - 29px);
  border-radius: 16px;
  background-color: white;
  position: absolute;
  top: 8px;
  bottom: 0px;
}

.map-controls-container-fullscreen {
  width: calc(100% - 0px) !important;
  max-width: calc(100% - 0px) !important;
  height: calc(100% - 8px) !important;
  top: 8px;
  bottom: 0px;
}

.map-controls-left-container {
  position: absolute;
  width: 28px;
  height: fit-content;
  left: 16px;
  bottom: 16px;
}

.map-controls-right-container {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 28px;
  height: fit-content;
}

.map-controls-top-right-container {
  position: absolute;
  z-index: 1050;
  top: 16px;
  right: 16px;
  width: 28px;
  height: fit-content;
}

.map-button-icon {
  font-size: 18px;
}

.map-menu-button {
  background-color: #27262e;
  color: #f1f1f1;
}

.map-menu-button:hover {
  background-color: #565465;
}

.map-button-visible {
  position: absolute;
  z-index: 1050;
  padding: 0px;
  width: 28px;
  height: 28px;
  left: 0px;
  opacity: 1;
  transition: left 0.35s;
}

.map-button-hidden {
  position: absolute;
  z-index: 0;
  padding: 0px;
  width: 28px;
  height: 28px;
  bottom: 0px !important;
  opacity: 0;
  transition: left 0.35s;
}

.map-button-attributes {
  padding: 0px;
  width: 28px;
  height: 28px;
}

@media only screen and (max-width: 1024px) {
  .map-controls-container {
    width: calc(100% - 12px);
    max-width: calc(100% - 12px);
    height: calc(100% - 17px);
    top: 6px;
    bottom: 0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1386px) {
  .map-controls-container {
    width: calc(100% - 38px);
    max-width: calc(100% - 38px);
    height: calc(100% - 17px);
    top: 6px;
    bottom: 0px;
  }
}

@media only screen and (min-width: 1386px) {
  .map-controls-container {
    width: calc(100% - 44px);
    max-width: calc(100% - 44px);
    height: calc(100% - 29px);
    top: 8px;
    bottom: 0px;
  }
}
