.filter-chip-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  min-width: fit-content;
  gap: 5px;
  height: 24px;
  border-radius: 14px;
  font-size: 0.75em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.125em;
  padding: 2px 6px 2px 8px;
  color: #4a6655;
}

.filter-chip-container-normal {
  background: linear-gradient(90deg, #01ffc2 0%, #01f0ff 100%);
}

.filter-chip-container-disabled {
  background: linear-gradient(90deg, #e7e7e7 0%, #e7e7e7 100%);
  padding: 2px 12px 2px 12px;
}

.filter-chip-container-scenario-a {
  background: linear-gradient(
    90deg,
    hsl(199, 49%, 85%) 0%,
    hsl(199, 49%, 75%) 100%
  );
}

.filter-chip-container-scenario-b {
  background: linear-gradient(
    90deg,
    hsl(18, 86%, 85%) 0%,
    hsl(18, 86%, 75%) 100%
  );
}

.filter-chip-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  color: #4a6655;
  font-size: 1.2em;
  line-height: 0.6em;
  padding: 0px;
}
