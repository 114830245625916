.target-widget-container {
  width: calc(100% - 20px);
  height: calc(100% - 30px);
  background-color: #ffffff;
  border-radius: 16px;
  padding: 15px 5px 15px 15px;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.target-widget-title-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 7px;
}

.target-widget-data-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0px;
  min-height: 0px;
}

.target-widget-group-selection-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 10px 2px 10px 0px;
}

.target-widget-buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-right: 15px;
  gap: 10px;
}

.target-widget-group-title-container {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 2em;
  color: #747475;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 15px;
}
